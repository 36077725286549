import { Component } from '@angular/core';
import { CustomToastrConfig } from "@models/custom-toastr-config.interface";
import { Toast } from "ngx-toastr";

@Component({
  selector: '[app-delete-semester-toast]',
  styleUrls: ['./custom-toast.component.scss'],
  template: `
    <div class="row"
         [style.display]="state.value === 'inactive' ? 'none' : ''"
         (keydown.escape)="remove()">
      <div class="message">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert"
             [class]="options.messageClass" [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert"
             [class]="options.messageClass">
          {{ message }}
        </div>
      </div>
      <div class="controls text-right">
        <a *ngIf="options.cancelButton"
           class="btn btn-sm cancel-btn"
           (click)="action($event)"
           (keydown.enter)="action($event)"
           [tabindex]="0"
           role="button"
           aria-label="cancel">
          {{ cancelString }}
        </a>
        <a *ngIf="options.closeButton"
           class="btn btn-sm close-btn"
           (click)="remove()"
           (keydown.enter)="remove()"
           [tabindex]="0"
           role="button"
           aria-label="close">
          <mat-icon class="close-icon">close</mat-icon>
        </a>
      </div>
    </div>
    <div *ngIf="options.progressBar" #toastProgressBar>
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  preserveWhitespaces: false,
})
export class CustomToastComponent extends Toast {
  cancelString = 'Cancel';
  override options = <CustomToastrConfig>this.options;

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    this.remove()
    return false;
  }
}
