import { IPeConfigurableSessionVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadAll = createAction(
  "[Admin] Load All Pe Configurable Sessions",
  props<{ termCode: number }>()
);

export const sessionsInsertedForTerm = createAction(
  "[Admin Pe Configurable Sessions Effect] Pe Configurable Sessions Loaded For Term",
  props<{ sessions: IPeConfigurableSessionVM[], termCode: number }>()
);

export const loadAllFailure = createAction(
  "[Admin Pe Configurable Sessions Effect] Pe Configurable Sessions For Term Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const clearError = createAction(
  "[Admin Pe Configurable Sessions Action] Clear Pe Configurable Sessions Error"
);

