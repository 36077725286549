import { ACTIVE_TERMS } from "./active-terms.constant";
import { ARCHIVED_TERMS } from "./archived-terms.constant";

export class SectionInstructor implements ISectionInstructor {
  sectionId?: number;
  rnrRoleCode?: string | undefined;
  uscId?: string | undefined;
  eId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  personName?: string | undefined;
  sisSeqNumber?: string | undefined;

  constructor(data?: ISectionInstructor) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.sectionId = _data["sectionId"];
      this.rnrRoleCode = _data["rnrRoleCode"];
      this.uscId = _data["uscId"];
      this.eId = _data["eId"];
      this.firstName = _data["firstName"];
      this.lastName = _data["lastName"];
      this.personName = _data["personName"];
      this.sisSeqNumber = _data["sisSeqNumber"];
    }
  }

  static fromJS(data: any): SectionInstructor {
    data = typeof data === 'object' ? data : {};
    let result = new SectionInstructor();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["sectionId"] = this.sectionId;
    data["rnrRoleCode"] = this.rnrRoleCode;
    data["uscId"] = this.uscId;
    data["eId"] = this.eId;
    data["firstName"] = this.firstName;
    data["lastName"] = this.lastName;
    data["personName"] = this.personName;
    data["sisSeqNumber"] = this.sisSeqNumber;
    return data;
  }

  static createFakeInstructor(termCode: number, index: number): SectionInstructor {
    const stringId: string = `${ termCode }${ index }`;
    const intId: number = parseInt(stringId);

    return new SectionInstructor({
      sectionId: intId,
      rnrRoleCode: "IN",
      uscId: stringId,
      firstName: "First " + (index - 6),
      lastName: "Last " + (index - 6),
      personName: "Person",
      sisSeqNumber: stringId
    });
  }
}

export interface ISectionInstructor {
  sectionId?: number;
  rnrRoleCode?: string | undefined;
  uscId?: string | undefined;
  eId?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  personName?: string | undefined;
  sisSeqNumber?: string | undefined;
}

//todo: fix these
export const SectionInstructors: ISectionInstructor[] = [
  new SectionInstructor({
    sectionId:  33001,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Kanti",
    lastName: "McMahon",
    personName: "Kanti McMahon",
  }),
  new SectionInstructor({
    sectionId:  33002,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Tulugaq",
    lastName: "Alescio",
    personName: "Tulugaq Alescio",
  }),
  new SectionInstructor({
    sectionId:  33003,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Armani",
    lastName: "Sempers",
    personName: "Armani Sempers",
  }),
  new SectionInstructor({
    sectionId: 55411,
    rnrRoleCode: "IN",
    uscId: "1111111",
    firstName: "Marisa",
    lastName: "Mandler",
    personName: "Marisa Mandler",
  }),
  new SectionInstructor({
    sectionId: 33432,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Jason",
    lastName: "Smith",
    personName: "Jason Smith",
  }),

  new SectionInstructor({
    sectionId: 33433,
    rnrRoleCode: "IN",
    uscId: "1111111",
    firstName: "Stephanie",
    lastName: "Jackson",
    personName: "Stephanie Jackson",
  }),
  new SectionInstructor({
    sectionId: 33434,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Jason",
    lastName: "Smith",
    personName: "Jason Smith",
  }),
  new SectionInstructor({
    sectionId: 33435,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Jason",
    lastName: "Smith",
    personName: "Jason Smith",
  }),
  new SectionInstructor({
    sectionId: 33436,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Jason",
    lastName: "Smith",
    personName: "Jason Smith",
  }),
  new SectionInstructor({
    sectionId: 10011,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Hartley",
    lastName: "Bull",
    personName: "Hartley Bull",
  }),
  new SectionInstructor({
    sectionId: 10012,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Hartley",
    lastName: "Bull",
    personName: "Hartley Bull",
  }),
  new SectionInstructor({
    sectionId: 10022,
    rnrRoleCode: "IN",
    uscId: undefined,
    firstName: "Vic",
    lastName: "Thomas",
    personName: "Vic Thomas",
  }),
  new SectionInstructor({
    sectionId: 11808,
    rnrRoleCode: "IN",
    uscId: "111111111",
    firstName: "Hector",
    lastName: "Reyes",
    personName: "Hector Reyes",
  }),

  ...ACTIVE_TERMS.map(t => t.termCode)
    .map(termCode => [
      SectionInstructor.createFakeInstructor(termCode!, 7),
      SectionInstructor.createFakeInstructor(termCode!, 8),
      SectionInstructor.createFakeInstructor(termCode!, 9),
    ]).flat(),
  ...ARCHIVED_TERMS.map(t => t.termCode)
    .map(termCode => [
      SectionInstructor.createFakeInstructor(termCode!, 7),
      SectionInstructor.createFakeInstructor(termCode!, 8),
      SectionInstructor.createFakeInstructor(termCode!, 9),
    ]).flat()
];
