import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { map, Observable } from "rxjs";
import { AnnouncementVM } from "./WebRegApiClient";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class AnnouncementService {


  constructor(private apiService: WebRegService, private http: HttpClient) {
  }

  getAnnouncement(): Observable<AnnouncementVM> {
    // let a = this.http
    //         .get<AnnouncementVM>("/announcement/getannouncement")
    //         .pipe(map(data => console.log(data)));

    // return this.http.get<AnnouncementVM>("/announcement/getannouncement");

    return this.apiService.apiAnnouncementGetAnnouncement()
      .pipe(map(resp => {
        return resp;
      }));
  }

  postAnnouncement(message: AnnouncementVM): Observable<void> {
    return this.apiService.apiAnnouncementPostAnnouncement(message)
      .pipe(map(resp => {
        return resp;
      }));
  }
}
