import { IProgramDetailsVM } from "@app/core/services/WebRegApiClient";
import { ProgramDetailsActions } from "@app/store/program-details/program-details-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface ProgramDetailsState extends EntityState<IProgramDetailsVM> {
  areProgramDetailsLoadedForProgram: Record<string, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: IProgramDetailsVM): string {
  return <string>a.prefix;
}

export const areProgramDetailsLoadedForProgram = (state: ProgramDetailsState, prefix: string) => state.areProgramDetailsLoadedForProgram[prefix];

export const adapter = createEntityAdapter<IProgramDetailsVM>(
  {
    selectId: selectId
  }
);

export const initialProgramDetailsState = adapter.getInitialState({
  areProgramDetailsLoadedForProgram: {}
});

export const getErrorMessage = (state: ProgramDetailsState) => state.error;

export const programDetailsReducer = createReducer(
  initialProgramDetailsState,

  on(ProgramDetailsActions.programDetailsInsertedForProgram, (state, action) =>
    adapter.addOne(action.programDetails, {
      ...state, areProgramDetailsLoadedForProgram: {
        ...state.areProgramDetailsLoadedForProgram,
        [action.prefix]: true
      }
    })),

  on(ProgramDetailsActions.loadProgramDetailsFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(ProgramDetailsActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
