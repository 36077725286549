import { Injectable } from "@angular/core";
import { ProgramsService } from "@app/core/services/programs.service";
import { ProgramActions } from "@app/store/programs/programs-action-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, mergeMap, of } from "rxjs";

@Injectable()
export class ProgramsEffects {

  constructor(private actions$: Actions,
              private programsService: ProgramsService) {
  }

  //#region READ/LOAD
  loadAll$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ProgramActions.loadAll),
        concatMap(action => this.programsService.getAll()),
        map(programs => {
            return ProgramActions.programsInserted({ programs });
          },
        )
      )
  );

  loadProgramsForTermCode$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ProgramActions.loadProgramsByTermCode),
        map(action => action),
        mergeMap(action => this.programsService.getByTermCode(action.termCode).pipe(
            map(programs => {
                return ProgramActions.programsInsertedForTerm({ programs, termCode: action.termCode });
              },
            ),
            catchError(error => {
              return of(ProgramActions.loadProgramsByTermCodeFailure({
                error: error
              }))
            })
          )
        )));
  //#endregion

  //#region CREATE

  //#endregion

  //#region DELETE

  //#endregion

  //#region UPDATE

  //#endregion
}
