import { ITermVM, TermSeason, TermStatus, TermVM } from "@app/core/services/WebRegApiClient";

export const ACTIVE_TERMS: ITermVM[] = [
  new TermVM(
    {
      termCode: 20233,
      season: TermSeason.Fall,
      year: 2023,
      seasonName: 'Fall',
      statusCodes: [TermStatus.Active]
    }
  ),
  new TermVM(
    {
      termCode: 20232,
      season: TermSeason.Summer,
      year: 2023,
      seasonName: 'Summer',
      statusCodes: [TermStatus.Active]
    }
  ),
  new TermVM(
    {
      termCode: 20231,
      season: TermSeason.Spring,
      year: 2023,
      seasonName: 'Spring',
      statusCodes: [TermStatus.Active]
    }
  ),
]
