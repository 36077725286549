export class School implements ISchool {
  prefix?: string | undefined;
  description?: string | undefined;
  sortIndex?: number;

  constructor(data?: ISchool) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.prefix = _data["prefix"];
      this.description = _data["description"];
      this.sortIndex = _data["sortIndex"];
    }
  }

  static fromJS(data: any): School {
    data = typeof data === 'object' ? data : {};
    let result = new School();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["prefix"] = this.prefix;
    data["description"] = this.description;
    data["sortIndex"] = this.sortIndex;
    return data;
  }
}

export interface ISchool {
  prefix?: string | undefined;
  description?: string | undefined;
  sortIndex?: number;
}

export const SCHOOLS: ISchool[] = [
  new School({
    prefix: "LAS",
    description: "Dornsife College of Letters, Arts and Sciences",
    sortIndex: 0
  }),
  new School(
    {
      prefix: "ACCT",
      description: "Leventhal School of Accounting",
      sortIndex: 1
    }),
  new School(
    {
      prefix: "ARCH",
      description: "Architecture",
      sortIndex: 2
    }),
  new School({
    prefix: "FA",
    description: "Roski School of Art and Design",
    sortIndex: 3
  }),
  new School({
    prefix: "BVC",
    description: "Bovard College",
    sortIndex: 4
  }),
  new School({
    prefix: "BUS",
    description: "Marshall School of Business",
    sortIndex: 5
  }),
  new School({
    prefix: "CNTV",
    description: "Cinematic Arts",
    sortIndex: 6
  }),
  new School(
    {
      prefix: "ANSC",
      description: "Annenberg School for Communication and Journalism",
      sortIndex: 7
    }),
  new School({
    prefix: "DANC",
    description: "Kaufman School of Dance",
    sortIndex: 8
  }),
  new School({
    prefix: "DENT",
    description: "Ostrow School of Dentistry",
    sortIndex: 9
  }),
  new School({
    prefix: "THTR",
    description: "Dramatic Arts",
    sortIndex: 10
  }),
  new School({
    prefix: "EDUC",
    description: "Rossier School of Education",
    sortIndex: 11
  }),
  new School({
    prefix: "ENGR",
    description: "Viterbi School of Engineering",
    sortIndex: 12
  }),
  new School({
    prefix: "GERO",
    description: "Davis School of Gerontology",
    sortIndex: 13
  }),
  new School({
    prefix: "GRAD",
    description: "Graduate Studies",
    sortIndex: 14
  }),
  new School({
    prefix: "LAW",
    description: "Law",
    sortIndex: 15
  }),
  new School({
    prefix: "MED",
    description: "Keck School of Medicine",
    sortIndex: 16
  }),
  new School({
    prefix: "MUS",
    description: "Thornton School of Music",
    sortIndex: 17
  }),
  new School({
    prefix: "OT",
    description: "Chan Division of Occupational Science and Occupational Therapy",
    sortIndex: 18
  }),
  new School({
    prefix: "PHAR",
    description: "School of Pharmacy",
    sortIndex: 19
  }),
  new School({
    prefix: "PT",
    description: "Physical Therapy",
    sortIndex: 20
  }),
  new School({
    prefix: "PPD",
    description: "Price School of Public Policy",
    sortIndex: 21
  }),
  new School({
    prefix: "SOWK",
    description: "Dworak-Peck School of Social Work",
    sortIndex: 22
  }),
  new School({
    prefix: 'ACAD',
    description: 'Jimmy Iovine and Andre Young Academy',
    sortIndex: 23
  }),
];
