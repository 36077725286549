import { IPeSession } from "@app/core/services/WebRegApiClient";
import { createAction, props } from "@ngrx/store";

export const loadSessionByRnrSessionId = createAction(
  "[Sessions Effect] Load Session By RnrSessionId",
  props<{ rnrSessionId: number }>()
);

export const sessionInserted = createAction(
  "[Sessions Effect] Sessions Loaded",
  props<{ session: IPeSession, rnrSessionId: number }>()
);
