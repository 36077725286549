import { ICoursePrefixVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadAll = createAction(
  "[Admin] Load All CoursePrefixes"
);

export const coursePrefixesInserted = createAction(
  "[CoursePrefixes Effect] CoursePrefixes Loaded",
  props<{ coursePrefixes: ICoursePrefixVM[] }>()
);

export const loadAllFailure = createAction(
  "[CoursePrefixes Effect] CoursePrefixes Load Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const clearError = createAction(
  "[CoursePrefixes Actions] Clear CoursePrefixes Error"
);
