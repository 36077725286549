import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { APP_BASE_HREF } from "@angular/common";
import { Store } from "@ngrx/store";
import { AppState } from "@app/store/app.reducer";
import { catchError, concatMap, defaultIfEmpty, finalize, map, Observable, of, tap } from "rxjs";

type CanActivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;
export const AdminAuthGuard: CanActivateFn = (route,
                                              state,
                                              store: Store<AppState> = inject(Store<AppState>),
                                              baseHref: string = inject(APP_BASE_HREF)): CanActivateType => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);

  baseHref = baseHref ?? "";
  if (baseHref.charAt(baseHref.length - 1) == '/') {
    baseHref = baseHref.slice(0, -1);
  }

  let loading = false;

  return authService.getIsAuthenticated().pipe(
    map(isLoggedIn => {
      if (!loading && !isLoggedIn) {
        loading = true;
        const redirectUrl = `${baseHref}${state.url}`;
        window.location.assign(`bff/login?returnUrl=${redirectUrl}`);
        return false;
      }

      return isLoggedIn
    }),
    concatMap(isLoggedIn => authService.getIsAdmin().pipe(
      tap(isAdmin => {
        if (isLoggedIn && isAdmin) {
          return true;
        }
        router.navigate(['/home']);
        return false;
      }))),
    defaultIfEmpty(false),
    catchError((err) => {
      console.log('error in auth guard: ', err);
      return of(false)
    }),
    finalize(() => {
      loading = false;
      return false;
    }));
}
