import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { ProgramCourseListActions } from "@app/store/program-course-list/program-course-list-action-types";
import { ProgramCourseListService } from "@app/core/services/program-course-list.service";

@Injectable()
export class ProgramCourseListEffects {

  constructor(private actions$: Actions,
              private programCourseListService: ProgramCourseListService) {
  }

  //#region READ/LOAD
  loadProgramCourseList$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(ProgramCourseListActions.loadProgramCourseList),
        map(action => action),
        mergeMap(action => this.programCourseListService.getCourses(action.termCode, action.schoolCode, action.programCode).pipe(
          map(programCourseList => {
              return ProgramCourseListActions.loadProgramCourseListSuccess({
                programCourseList,
                concatenatedKey: action.termCode + action.schoolCode + action.programCode
              });
            },
          ),
          catchError(error => {
            return of(ProgramCourseListActions.loadProgramCourseListFailure({
              error: error
            }))
          }),
        )))
  );
  //#endregion
}
