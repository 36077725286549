import { ACTIVE_TERMS } from "./active-terms.constant";
import { ARCHIVED_TERMS } from "./archived-terms.constant";

export class SessionModel implements ISessionModel {
  rnrSessionId?: number;
  sisSessionId?: string | undefined;
  termCode?: string | undefined;
  description?: string | undefined;
  rnrSessionCode?: string | undefined;
  gradingCycleCode?: string | undefined;
  partHealthFeeCode?: string | undefined;
  summerWeekHealthFeeCode?: string | undefined;
  summerMaxHealthFeeCode?: string | undefined;
  studentFeeCode?: string | undefined;
  lastApplyDate?: Date | undefined;
  preRegBeginDate?: Date | undefined;
  preRegEndDate?: Date | undefined;
  earlyRegBeginDate?: Date | undefined;
  earlyRegEndDate?: Date | undefined;
  regBeginDate?: Date | undefined;
  regEndDate?: Date | undefined;
  classBeginDate?: Date | undefined;
  classEndDate?: Date | undefined;
  lateRegBeginDate?: Date | undefined;
  lateRegEndDate?: Date | undefined;
  lastAddDropDate?: Date | undefined;
  healthInsuranceDueDate?: Date | undefined;
  withdrawWowDate?: Date | undefined;
  withdrawWwDate?: Date | undefined;
  gradePostDate?: Date | undefined;
  fullTimeUnitCode?: string | undefined;
  stopDate?: Date | undefined;
  orientationFeeCode?: string | undefined;
  week2BeginDate?: Date | undefined;
  week2EndDate?: Date | undefined;
  week2PercentPenalty?: number;
  week2FeeCode?: string | undefined;
  week3BeginDate?: Date | undefined;
  week3EndDate?: Date | undefined;
  week3PercentPenalty?: number;
  week3FeeCode?: string | undefined;
  orientationMandatory?: string | undefined;
  oissMandatory?: string | undefined;
  oissFeeCode?: string | undefined;
  gradOrientationMandatory?: string | undefined;
  gradOrientationFeeCode?: string | undefined;
  finalExamBeginDate?: Date | undefined;
  finalExamEndDate?: Date | undefined;
  insuranceMinimumUnits?: number;
  uscCampusCode?: string | undefined;
  midtermGradingBeginDate?: Date | undefined;
  midtermGradingEndDate?: Date | undefined;
  finalGradingBeginDate?: Date | undefined;
  finalGradingEndDate?: Date | undefined;
  contactName?: string | undefined;
  contactPhone?: string | undefined;
  break1BeginDate?: Date | undefined;
  break1EndDate?: Date | undefined;
  break2BeginDate?: Date | undefined;
  break2EndDate?: Date | undefined;
  lastEnrollmentOptionDate?: Date | undefined;

  constructor(data?: ISessionModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.rnrSessionId = _data["rnrSessionId"];
      this.sisSessionId = _data["sisSessionId"];
      this.termCode = _data["termCode"];
      this.description = _data["description"];
      this.rnrSessionCode = _data["rnrSessionCode"];
      this.gradingCycleCode = _data["gradingCycleCode"];
      this.partHealthFeeCode = _data["partHealthFeeCode"];
      this.summerWeekHealthFeeCode = _data["summerWeekHealthFeeCode"];
      this.summerMaxHealthFeeCode = _data["summerMaxHealthFeeCode"];
      this.studentFeeCode = _data["studentFeeCode"];
      this.lastApplyDate = _data["lastApplyDate"] ? new Date(_data["lastApplyDate"].toString()) : <any>undefined;
      this.preRegBeginDate = _data["preRegBeginDate"] ? new Date(_data["preRegBeginDate"].toString()) : <any>undefined;
      this.preRegEndDate = _data["preRegEndDate"] ? new Date(_data["preRegEndDate"].toString()) : <any>undefined;
      this.earlyRegBeginDate = _data["earlyRegBeginDate"] ? new Date(_data["earlyRegBeginDate"].toString()) : <any>undefined;
      this.earlyRegEndDate = _data["earlyRegEndDate"] ? new Date(_data["earlyRegEndDate"].toString()) : <any>undefined;
      this.regBeginDate = _data["regBeginDate"] ? new Date(_data["regBeginDate"].toString()) : <any>undefined;
      this.regEndDate = _data["regEndDate"] ? new Date(_data["regEndDate"].toString()) : <any>undefined;
      this.classBeginDate = _data["classBeginDate"] ? new Date(_data["classBeginDate"].toString()) : <any>undefined;
      this.classEndDate = _data["classEndDate"] ? new Date(_data["classEndDate"].toString()) : <any>undefined;
      this.lateRegBeginDate = _data["lateRegBeginDate"] ? new Date(_data["lateRegBeginDate"].toString()) : <any>undefined;
      this.lateRegEndDate = _data["lateRegEndDate"] ? new Date(_data["lateRegEndDate"].toString()) : <any>undefined;
      this.lastAddDropDate = _data["lastAddDropDate"] ? new Date(_data["lastAddDropDate"].toString()) : <any>undefined;
      this.healthInsuranceDueDate = _data["healthInsuranceDueDate"] ? new Date(_data["healthInsuranceDueDate"].toString()) : <any>undefined;
      this.withdrawWowDate = _data["withdrawWowDate"] ? new Date(_data["withdrawWowDate"].toString()) : <any>undefined;
      this.withdrawWwDate = _data["withdrawWwDate"] ? new Date(_data["withdrawWwDate"].toString()) : <any>undefined;
      this.gradePostDate = _data["gradePostDate"] ? new Date(_data["gradePostDate"].toString()) : <any>undefined;
      this.fullTimeUnitCode = _data["fullTimeUnitCode"];
      this.stopDate = _data["stopDate"] ? new Date(_data["stopDate"].toString()) : <any>undefined;
      this.orientationFeeCode = _data["orientationFeeCode"];
      this.week2BeginDate = _data["week2BeginDate"] ? new Date(_data["week2BeginDate"].toString()) : <any>undefined;
      this.week2EndDate = _data["week2EndDate"] ? new Date(_data["week2EndDate"].toString()) : <any>undefined;
      this.week2PercentPenalty = _data["week2PercentPenalty"];
      this.week2FeeCode = _data["week2FeeCode"];
      this.week3BeginDate = _data["week3BeginDate"] ? new Date(_data["week3BeginDate"].toString()) : <any>undefined;
      this.week3EndDate = _data["week3EndDate"] ? new Date(_data["week3EndDate"].toString()) : <any>undefined;
      this.week3PercentPenalty = _data["week3PercentPenalty"];
      this.week3FeeCode = _data["week3FeeCode"];
      this.orientationMandatory = _data["orientationMandatory"];
      this.oissMandatory = _data["oissMandatory"];
      this.oissFeeCode = _data["oissFeeCode"];
      this.gradOrientationMandatory = _data["gradOrientationMandatory"];
      this.gradOrientationFeeCode = _data["gradOrientationFeeCode"];
      this.finalExamBeginDate = _data["finalExamBeginDate"] ? new Date(_data["finalExamBeginDate"].toString()) : <any>undefined;
      this.finalExamEndDate = _data["finalExamEndDate"] ? new Date(_data["finalExamEndDate"].toString()) : <any>undefined;
      this.insuranceMinimumUnits = _data["insuranceMinimumUnits"];
      this.uscCampusCode = _data["uscCampusCode"];
      this.midtermGradingBeginDate = _data["midtermGradingBeginDate"] ? new Date(_data["midtermGradingBeginDate"].toString()) : <any>undefined;
      this.midtermGradingEndDate = _data["midtermGradingEndDate"] ? new Date(_data["midtermGradingEndDate"].toString()) : <any>undefined;
      this.finalGradingBeginDate = _data["finalGradingBeginDate"] ? new Date(_data["finalGradingBeginDate"].toString()) : <any>undefined;
      this.finalGradingEndDate = _data["finalGradingEndDate"] ? new Date(_data["finalGradingEndDate"].toString()) : <any>undefined;
      this.contactName = _data["contactName"];
      this.contactPhone = _data["contactPhone"];
      this.break1BeginDate = _data["break1BeginDate"] ? new Date(_data["break1BeginDate"].toString()) : <any>undefined;
      this.break1EndDate = _data["break1EndDate"] ? new Date(_data["break1EndDate"].toString()) : <any>undefined;
      this.break2BeginDate = _data["break2BeginDate"] ? new Date(_data["break2BeginDate"].toString()) : <any>undefined;
      this.break2EndDate = _data["break2EndDate"] ? new Date(_data["break2EndDate"].toString()) : <any>undefined;
      this.lastEnrollmentOptionDate = _data["lastEnrollmentOptionDate"] ? new Date(_data["lastEnrollmentOptionDate"].toString()) : <any>undefined;
    }
  }

  static fromJS(data: any): SessionModel {
    data = typeof data === 'object' ? data : {};
    let result = new SessionModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["rnrSessionId"] = this.rnrSessionId;
    data["sisSessionId"] = this.sisSessionId;
    data["termCode"] = this.termCode;
    data["description"] = this.description;
    data["rnrSessionCode"] = this.rnrSessionCode;
    data["gradingCycleCode"] = this.gradingCycleCode;
    data["partHealthFeeCode"] = this.partHealthFeeCode;
    data["summerWeekHealthFeeCode"] = this.summerWeekHealthFeeCode;
    data["summerMaxHealthFeeCode"] = this.summerMaxHealthFeeCode;
    data["studentFeeCode"] = this.studentFeeCode;
    data["lastApplyDate"] = this.lastApplyDate ? this.lastApplyDate.toISOString() : <any>undefined;
    data["preRegBeginDate"] = this.preRegBeginDate ? this.preRegBeginDate.toISOString() : <any>undefined;
    data["preRegEndDate"] = this.preRegEndDate ? this.preRegEndDate.toISOString() : <any>undefined;
    data["earlyRegBeginDate"] = this.earlyRegBeginDate ? this.earlyRegBeginDate.toISOString() : <any>undefined;
    data["earlyRegEndDate"] = this.earlyRegEndDate ? this.earlyRegEndDate.toISOString() : <any>undefined;
    data["regBeginDate"] = this.regBeginDate ? this.regBeginDate.toISOString() : <any>undefined;
    data["regEndDate"] = this.regEndDate ? this.regEndDate.toISOString() : <any>undefined;
    data["classBeginDate"] = this.classBeginDate ? this.classBeginDate.toISOString() : <any>undefined;
    data["classEndDate"] = this.classEndDate ? this.classEndDate.toISOString() : <any>undefined;
    data["lateRegBeginDate"] = this.lateRegBeginDate ? this.lateRegBeginDate.toISOString() : <any>undefined;
    data["lateRegEndDate"] = this.lateRegEndDate ? this.lateRegEndDate.toISOString() : <any>undefined;
    data["lastAddDropDate"] = this.lastAddDropDate ? this.lastAddDropDate.toISOString() : <any>undefined;
    data["healthInsuranceDueDate"] = this.healthInsuranceDueDate ? this.healthInsuranceDueDate.toISOString() : <any>undefined;
    data["withdrawWowDate"] = this.withdrawWowDate ? this.withdrawWowDate.toISOString() : <any>undefined;
    data["withdrawWwDate"] = this.withdrawWwDate ? this.withdrawWwDate.toISOString() : <any>undefined;
    data["gradePostDate"] = this.gradePostDate ? this.gradePostDate.toISOString() : <any>undefined;
    data["fullTimeUnitCode"] = this.fullTimeUnitCode;
    data["stopDate"] = this.stopDate ? this.stopDate.toISOString() : <any>undefined;
    data["orientationFeeCode"] = this.orientationFeeCode;
    data["week2BeginDate"] = this.week2BeginDate ? this.week2BeginDate.toISOString() : <any>undefined;
    data["week2EndDate"] = this.week2EndDate ? this.week2EndDate.toISOString() : <any>undefined;
    data["week2PercentPenalty"] = this.week2PercentPenalty;
    data["week2FeeCode"] = this.week2FeeCode;
    data["week3BeginDate"] = this.week3BeginDate ? this.week3BeginDate.toISOString() : <any>undefined;
    data["week3EndDate"] = this.week3EndDate ? this.week3EndDate.toISOString() : <any>undefined;
    data["week3PercentPenalty"] = this.week3PercentPenalty;
    data["week3FeeCode"] = this.week3FeeCode;
    data["orientationMandatory"] = this.orientationMandatory;
    data["oissMandatory"] = this.oissMandatory;
    data["oissFeeCode"] = this.oissFeeCode;
    data["gradOrientationMandatory"] = this.gradOrientationMandatory;
    data["gradOrientationFeeCode"] = this.gradOrientationFeeCode;
    data["finalExamBeginDate"] = this.finalExamBeginDate ? this.finalExamBeginDate.toISOString() : <any>undefined;
    data["finalExamEndDate"] = this.finalExamEndDate ? this.finalExamEndDate.toISOString() : <any>undefined;
    data["insuranceMinimumUnits"] = this.insuranceMinimumUnits;
    data["uscCampusCode"] = this.uscCampusCode;
    data["midtermGradingBeginDate"] = this.midtermGradingBeginDate ? this.midtermGradingBeginDate.toISOString() : <any>undefined;
    data["midtermGradingEndDate"] = this.midtermGradingEndDate ? this.midtermGradingEndDate.toISOString() : <any>undefined;
    data["finalGradingBeginDate"] = this.finalGradingBeginDate ? this.finalGradingBeginDate.toISOString() : <any>undefined;
    data["finalGradingEndDate"] = this.finalGradingEndDate ? this.finalGradingEndDate.toISOString() : <any>undefined;
    data["contactName"] = this.contactName;
    data["contactPhone"] = this.contactPhone;
    data["break1BeginDate"] = this.break1BeginDate ? this.break1BeginDate.toISOString() : <any>undefined;
    data["break1EndDate"] = this.break1EndDate ? this.break1EndDate.toISOString() : <any>undefined;
    data["break2BeginDate"] = this.break2BeginDate ? this.break2BeginDate.toISOString() : <any>undefined;
    data["break2EndDate"] = this.break2EndDate ? this.break2EndDate.toISOString() : <any>undefined;
    data["lastEnrollmentOptionDate"] = this.lastEnrollmentOptionDate ? this.lastEnrollmentOptionDate.toISOString() : <any>undefined;
    return data;
  }

  static createFakeSession(termCode: number, index: number): SessionModel {
    const stringId: string = `${termCode}${index}`;
    const intId: number = parseInt(stringId);

    return new SessionModel({
      rnrSessionId: intId,
      sisSessionId: "SIS" + stringId,
      termCode: stringId,
      description: `${termCode} Description`,
      rnrSessionCode: '00' + index,
      classBeginDate: new Date("2023-08-21"),
      classEndDate: new Date("2023-12-02"),
      lastAddDropDate: new Date("2023-09-08"),
      withdrawWowDate: new Date("2023-11-10"),
      fullTimeUnitCode: "6.0",
      finalExamBeginDate: new Date("2023-12-06"),
      finalExamEndDate: new Date("2023-12-13"),
      uscCampusCode: "UPC-1",
      finalGradingBeginDate: new Date("2023-12-06"),
      finalGradingEndDate: new Date("2023-12-19"),
      break1BeginDate: new Date("2023-11-22"),
      break1EndDate: new Date("2023-11-26"),
      lastEnrollmentOptionDate: new Date("2023-10-06"),
    });
  }
}

export interface ISessionModel {
  rnrSessionId?: number;
  sisSessionId?: string | undefined;
  termCode?: string | undefined;
  description?: string | undefined;
  rnrSessionCode?: string | undefined;
  gradingCycleCode?: string | undefined;
  partHealthFeeCode?: string | undefined;
  summerWeekHealthFeeCode?: string | undefined;
  summerMaxHealthFeeCode?: string | undefined;
  studentFeeCode?: string | undefined;
  lastApplyDate?: Date | undefined;
  preRegBeginDate?: Date | undefined;
  preRegEndDate?: Date | undefined;
  earlyRegBeginDate?: Date | undefined;
  earlyRegEndDate?: Date | undefined;
  regBeginDate?: Date | undefined;
  regEndDate?: Date | undefined;
  classBeginDate?: Date | undefined;
  classEndDate?: Date | undefined;
  lateRegBeginDate?: Date | undefined;
  lateRegEndDate?: Date | undefined;
  lastAddDropDate?: Date | undefined;
  healthInsuranceDueDate?: Date | undefined;
  withdrawWowDate?: Date | undefined;
  withdrawWwDate?: Date | undefined;
  gradePostDate?: Date | undefined;
  fullTimeUnitCode?: string | undefined;
  stopDate?: Date | undefined;
  orientationFeeCode?: string | undefined;
  week2BeginDate?: Date | undefined;
  week2EndDate?: Date | undefined;
  week2PercentPenalty?: number;
  week2FeeCode?: string | undefined;
  week3BeginDate?: Date | undefined;
  week3EndDate?: Date | undefined;
  week3PercentPenalty?: number;
  week3FeeCode?: string | undefined;
  orientationMandatory?: string | undefined;
  oissMandatory?: string | undefined;
  oissFeeCode?: string | undefined;
  gradOrientationMandatory?: string | undefined;
  gradOrientationFeeCode?: string | undefined;
  finalExamBeginDate?: Date | undefined;
  finalExamEndDate?: Date | undefined;
  insuranceMinimumUnits?: number;
  uscCampusCode?: string | undefined;
  midtermGradingBeginDate?: Date | undefined;
  midtermGradingEndDate?: Date | undefined;
  finalGradingBeginDate?: Date | undefined;
  finalGradingEndDate?: Date | undefined;
  contactName?: string | undefined;
  contactPhone?: string | undefined;
  break1BeginDate?: Date | undefined;
  break1EndDate?: Date | undefined;
  break2BeginDate?: Date | undefined;
  break2EndDate?: Date | undefined;
  lastEnrollmentOptionDate?: Date | undefined;
}


export const SESSIONS: ISessionModel[] = [
  ...[
    // new SessionModel({
    //   rnrSessionId: 20233,
    //   sisSessionId: "33433",
    //   termCode: (20233).toString(),
    //   description: `20233 Description`,
    //   rnrSessionCode: "003",
    //   classBeginDate: new Date("2023-08-21"),
    //   classEndDate: new Date("2023-12-02"),
    //   lastAddDropDate: new Date("2023-09-08"),
    //   withdrawWowDate: new Date("2023-11-10"),
    //   fullTimeUnitCode: "6.0",
    //   finalExamBeginDate: new Date("2023-12-06"),
    //   finalExamEndDate: new Date("2023-12-13"),
    //   uscCampusCode: "UPC-1",
    //   finalGradingBeginDate: new Date("2023-12-06"),
    //   finalGradingEndDate: new Date("2023-12-19"),
    //   break1BeginDate: new Date("2023-11-22"),
    //   break1EndDate: new Date("2023-11-26"),
    //   lastEnrollmentOptionDate: new Date("2023-10-06"),
    // })
  ],
  ...ACTIVE_TERMS.map(t => t)
    .map((t) => {
      const r = [
        new SessionModel({
          rnrSessionId: parseInt(`${t.termCode}`),
          sisSessionId: `${t.termCode}`,
          termCode: `${t.termCode}`,
          description: `${t.year} ${t.seasonName} Session`,
          rnrSessionCode: "001",
          classBeginDate: new Date(`${t.year}-08-21`),
          classEndDate: new Date(`${t.year}-12-02`),
          lastAddDropDate: new Date(`${t.year}-09-08`),
          withdrawWowDate: new Date(`${t.year}-11-10`),
          fullTimeUnitCode: "6.0",
          finalExamBeginDate: new Date(`${t.year}-12-06`),
          finalExamEndDate: new Date(`${t.year}-12-13`),
          uscCampusCode: "UPC-1",
          finalGradingBeginDate: new Date(`${t.year}-12-06`),
          finalGradingEndDate: new Date(`${t.year}-12-19`),
          break1BeginDate: new Date(`${t.year}-11-22`),
          break1EndDate: new Date(`${t.year}-11-26`),
          lastEnrollmentOptionDate: new Date(`${t.year}-10-06`),
        }),
      ]

      return [...r];
    }).flat(),

    ...ARCHIVED_TERMS.map(t => t)
    .map((t) => {
      const r = [
        new SessionModel({
          rnrSessionId: parseInt(`${t.termCode}`),
          sisSessionId: `${t.termCode}`,
          termCode: `${t.termCode}`,
          description: `${t.year} ${t.seasonName} Session`,
          rnrSessionCode: "001",
          classBeginDate: new Date(`${t.year}-08-21`),
          classEndDate: new Date(`${t.year}-12-02`),
          lastAddDropDate: new Date(`${t.year}-09-08`),
          withdrawWowDate: new Date(`${t.year}-11-10`),
          fullTimeUnitCode: "6.0",
          finalExamBeginDate: new Date(`${t.year}-12-06`),
          finalExamEndDate: new Date(`${t.year}-12-13`),
          uscCampusCode: "UPC-1",
          finalGradingBeginDate: new Date(`${t.year}-12-06`),
          finalGradingEndDate: new Date(`${t.year}-12-19`),
          break1BeginDate: new Date(`${t.year}-11-22`),
          break1EndDate: new Date(`${t.year}-11-26`),
          lastEnrollmentOptionDate: new Date(`${t.year}-10-06`),
        }),
      ]

      return [...r];
    }).flat(),
]