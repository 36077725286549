import { ACTIVE_TERMS } from "./active-terms.constant";
import { ARCHIVED_TERMS } from "./archived-terms.constant";

export class SectionLocation implements ISectionLocation {
  sectionId?: number;
  dayCodes?: string[] | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
  location?: string | undefined;
  campusCode?: string | undefined;
  buildingCode?: string | undefined;
  roomNumber?: string | undefined;
  lastUpdtTs?: Date | undefined;
  sisSqNumber?: number;

  constructor(data?: ISectionLocation) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.sectionId = _data["sectionId"];
      if (Array.isArray(_data["dayCodes"])) {
        this.dayCodes = [] as any;
        for (let item of _data["dayCodes"])
          this.dayCodes!.push(item);
      }
      this.startTime = _data["startTime"];
      this.endTime = _data["endTime"];
      this.location = _data["location"];
      this.campusCode = _data["campusCode"];
      this.buildingCode = _data["buildingCode"];
      this.roomNumber = _data["roomNumber"];
      this.lastUpdtTs = _data["lastUpdtTs"] ? new Date(_data["lastUpdtTs"].toString()) : <any>undefined;
      this.sisSqNumber = _data["sisSqNumber"];
    }
  }

  static fromJS(data: any): SectionLocation {
    data = typeof data === 'object' ? data : {};
    let result = new SectionLocation();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["sectionId"] = this.sectionId;
    if (Array.isArray(this.dayCodes)) {
      data["dayCodes"] = [];
      for (let item of this.dayCodes)
        data["dayCodes"].push(item);
    }
    data["startTime"] = this.startTime;
    data["endTime"] = this.endTime;
    data["location"] = this.location;
    data["campusCode"] = this.campusCode;
    data["buildingCode"] = this.buildingCode;
    data["roomNumber"] = this.roomNumber;
    data["lastUpdtTs"] = this.lastUpdtTs ? this.lastUpdtTs.toISOString() : <any>undefined;
    data["sisSqNumber"] = this.sisSqNumber;
    return data;
  }
}

export interface ISectionLocation {
  sectionId?: number;
  dayCodes?: string[] | undefined;
  startTime?: string | undefined;
  endTime?: string | undefined;
  location?: string | undefined;
  campusCode?: string | undefined;
  buildingCode?: string | undefined;
  roomNumber?: string | undefined;
  // lastUpdtTs?: Date | undefined;
  // sisSqNumber?: number;
}

export const SECTION_LOCATIONS: ISectionLocation[] = [
  ...[
    new SectionLocation({
      sectionId: 33001,
      dayCodes: ['Mon', 'Wed'],
      startTime: "08:00",
      endTime: "10:00",
      location: "SGM 001",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "001"
    }),
    new SectionLocation({
      sectionId: 33021,
      dayCodes: ['Mon', 'Wed'],
      startTime: "08:00",
      endTime: "10:00",
      location: "SGM 111",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "111"
    }),
    new SectionLocation({
      sectionId: 33031,
      dayCodes: ['Mon', 'Wed'],
      startTime: "08:00",
      endTime: "10:00",
      location: "SGM 110",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "110"
    }),
    new SectionLocation({
      sectionId: 33041,
      dayCodes: ['Mon', 'Wed'],
      startTime: "08:00",
      endTime: "10:00",
      location: "SGM 112",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "112"
    }),
    new SectionLocation({
      sectionId: 33002,
      dayCodes: ['Mon', 'Wed'],
      startTime: "09:00",
      endTime: "11:00",
      location: "SGM 011",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "011"
    }),
    new SectionLocation({
      sectionId: 33002,
      dayCodes: ['Tue', 'Thu'],
      startTime: "09:00",
      endTime: "11:00",
      location: "SGM 011",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "011"
    }),
    new SectionLocation({
      sectionId: 33003,
      dayCodes: ['Fri'],
      startTime: "12:00",
      endTime: "3:00",
      location: "SGM 654",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "654"
    }),
    new SectionLocation({
      sectionId: 11805,
      dayCodes: ['Tue'],
      startTime: "14:30",
      endTime: "15:50",
      location: "THH 301",
      campusCode: "UPC",
      buildingCode: "THH",
      roomNumber: "301"
    }),
    new SectionLocation({
      sectionId: 33004,
      dayCodes: ['Monday'],
      startTime: "18:00",
      endTime: "21:00",
      location: "THH 311",
      campusCode: "UPC",
      buildingCode: "THH",
      roomNumber: "311"
    }),
    new SectionLocation({
      sectionId: 55411,
      dayCodes: ['Tue'],
      startTime: "15:00",
      endTime: "16:50",
      location: "WAH 102A",
      campusCode: "UPC",
      buildingCode: "WAH",
      roomNumber: "102A"
    }),
    //HIS 500 Start
    new SectionLocation({
      sectionId: 33432,
      dayCodes: ['Mon'],
      startTime: "08:00",
      endTime: "10:00",
      location: "SGM 207",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "207"
    }),
    new SectionLocation({
      sectionId: 33433,
      dayCodes: ['Mon', 'Wed'],
      startTime: "09:00",
      endTime: "10:00",
      location: "SGM 207",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "207"
    }),
    new SectionLocation({
      sectionId: 33434,
      dayCodes: ['Mon'],
      startTime: "13:00",
      endTime: "15:00",
      location: "SGM 207",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "207"
    }),
    new SectionLocation({
      sectionId: 33435,
      dayCodes: ['Tue', 'Thu'],
      startTime: "09:00",
      endTime: "10:00",
      location: "SGM 207",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "207"
    }),
    new SectionLocation({
      sectionId: 33436,
      dayCodes: ['Tue'],
      startTime: "13:00",
      endTime: "15:00",
      location: "SGM 207",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "207"
    }),
    new SectionLocation({
      sectionId: 33437,
      dayCodes: ['Fri'],
      startTime: "13:00",
      endTime: "15:00",
      location: "SGM 209",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "209"
    }),
    //HIS 500 End
    new SectionLocation({
      sectionId: 10011,
      dayCodes: ['Mon'],
      startTime: "14:30",
      endTime: "15:50",
      location: "THH 301",
      campusCode: "UPC",
      buildingCode: "THH",
      roomNumber: "301"
    }),
    new SectionLocation({
      sectionId: 10012,
      dayCodes: ['Fri'],
      startTime: "14:30",
      endTime: "15:50",
      location: "THH 301",
      campusCode: "UPC",
      buildingCode: "THH",
      roomNumber: "301"
    }),

    new SectionLocation({
      sectionId: 10022,
      dayCodes: ['Mon', 'Wed'],
      startTime: "10:30",
      endTime: "12:30",
      location: "THH 201",
      campusCode: "UPC",
      buildingCode: "THH",
      roomNumber: "201"
    }),

    //2022
    new SectionLocation({
      sectionId: 23001,
      dayCodes: ['Mon', 'Wed'],
      startTime: "08:00",
      endTime: "10:00",
      location: "SGM 001",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "001"
    }),
    new SectionLocation({
      sectionId: 23002,
      dayCodes: ['Mon', 'Wed'],
      startTime: "09:00",
      endTime: "11:00",
      location: "SGM 011",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "011"
    }),
    new SectionLocation({
      sectionId: 23002,
      dayCodes: ['Tue', 'Thu'],
      startTime: "09:00",
      endTime: "11:00",
      location: "SGM 011",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "011"
    }),
    new SectionLocation({
      sectionId: 23003,
      dayCodes: ['Fri'],
      startTime: "12:00",
      endTime: "3:00",
      location: "SGM 654",
      campusCode: "UPC",
      buildingCode: "SGM",
      roomNumber: "654"
    }),
    new SectionLocation({
      sectionId: 11808,
      dayCodes: ['Tue'],
      startTime: "14:30",
      endTime: "15:50",
      location: "WPH 301",
      campusCode: "UPC",
      buildingCode: "WPH",
      roomNumber: "206"
    }),
    new SectionLocation({
      sectionId: 23004,
      dayCodes: ['Monday'],
      startTime: "18:00",
      endTime: "21:00",
      location: "THH 311",
      campusCode: "UPC",
      buildingCode: "THH",
      roomNumber: "311"
    }),
    new SectionLocation({
      sectionId: 22411,
      dayCodes: ['Tue'],
      startTime: "15:00",
      endTime: "16:50",
      location: "WAH 102A",
      campusCode: "UPC",
      buildingCode: "WAH",
      roomNumber: "102A"
    }),


  ],

  ...ACTIVE_TERMS.map(t => {
    const actives = []
    for (let courseCount = 0; courseCount < 13; courseCount++) {
      for (let sectionCount = 0; sectionCount < 2; sectionCount++) {
        const startTime = Math.floor(Math.random() * (20 - 12 + 1)) + 12;
        actives.push(new SectionLocation({
            sectionId: parseInt(`${ t.termCode }${ courseCount }${ sectionCount }`),
            dayCodes: sectionCount % 2 === 0 ? ['Mon', 'Wed', 'Fri'] : ['Tue', 'Thu'],
            startTime: `${ startTime }:00`,
            endTime: `${ startTime + 2 }:00`,
            location: `OFF-${ t.termCode }${ courseCount }${ sectionCount }`,
            campusCode: "UPC",
            buildingCode: "OFF",
            roomNumber: `OFF-${ t.termCode }${ courseCount }${ sectionCount }`
          })
        )
      }
    }
    return actives;
  }).flat(),

  ...ARCHIVED_TERMS.map(t => {
    const actives = []
    for (let courseCount = 0; courseCount < 13; courseCount++) {
      for (let sectionCount = 0; sectionCount < 2; sectionCount++) {
        const startTime = Math.floor(Math.random() * (20 - 8 + 1)) + 8;
        actives.push(new SectionLocation({
            sectionId: parseInt(`${ t.termCode }${ courseCount }${ sectionCount }`),
            dayCodes: sectionCount % 2 === 0 ? ['Mon', 'Wed', 'Fri'] : ['Tue', 'Thu'],
            startTime: `${ startTime }:00`,
            endTime: `${ startTime + 2 }:00`,
            location: `OFF-${ t.termCode }${ courseCount }${ sectionCount }`,
            campusCode: "UPC",
            buildingCode: "OFF",
            roomNumber: `OFF-${ t.termCode }${ courseCount }${ sectionCount }`
          })
        )
      }
    }
    return actives;
  }).flat(),
];
