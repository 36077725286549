export class ClearanceModel implements IClearanceModel {
  effectiveDate?: Date | undefined;
  expirationDate?: Date | undefined;
  unitCode?: string | undefined;
  sectionId?: number;
  personId?: number;

  constructor(data?: IClearanceModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.effectiveDate = _data["effectiveDate"] ? new Date(_data["effectiveDate"].toString()) : <any>undefined;
      this.expirationDate = _data["expirationDate"] ? new Date(_data["expirationDate"].toString()) : <any>undefined;
      this.unitCode = _data["unitCode"];
      this.sectionId = _data["sectionId"];
      this.personId = _data["personId"];
    }
  }

  static fromJS(data: any): ClearanceModel {
    data = typeof data === 'object' ? data : {};
    let result = new ClearanceModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["effectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["expirationDate"] = this.expirationDate ? this.expirationDate.toISOString() : <any>undefined;
    data["unitCode"] = this.unitCode;
    data["sectionId"] = this.sectionId;
    data["personId"] = this.personId;
    return data;
  }
}

export interface IClearanceModel {
  effectiveDate?: Date | undefined;
  expirationDate?: Date | undefined;
  unitCode?: string | undefined;
  sectionId?: number;
  personId?: number;
}
