import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { map, Observable } from "rxjs";
import { IProgramDetailsVM, IProgramVM } from "./WebRegApiClient";


@Injectable()
export class ProgramsService {

  constructor(private apiService: WebRegService) {
  }

  getAll(): Observable<IProgramVM[]> {
    return this.apiService.apiPeProgramsAll()
      .pipe(map(resp => resp.map(r => r.toJSON() as IProgramVM)))
  }

  getByTermCode(termCode: number): Observable<IProgramVM[]> {
    return this.apiService.apiProgramsTermCode(termCode)
      .pipe(map(resp => resp.map(r => r.toJSON() as IProgramVM)))
  }

  getByTermCodeAndSchoolCode(termCode: number, schoolCode: string): Observable<IProgramVM[]> {
    return this.apiService.apiProgramsTermCode(termCode)
      .pipe(map(resp => resp.map(r => r.toJSON() as IProgramVM)
        .filter(p => p.schools?.map(s => s.prefix).includes(schoolCode))
      ))
  }

  getDetails(termCode: number, schoolCode: string, programCode: string): Observable<IProgramDetailsVM> {
    return this.apiService.apiProgramsPrefix(termCode, schoolCode, programCode)
      .pipe(map(r => r.toJSON() as IProgramDetailsVM)
      );
  }
}
