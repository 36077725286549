import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    private initialized = false;

    constructor() {
        this.datadogInit();
    }

    private datadogInit() {
        if (!environment?.hasDatadog) {
            return;
        }

        // @ts-ignore
        datadogRum.init(environment.datadog);
        datadogRum.startSessionReplayRecording();

        this.initialized = true;
    }

    public datadogInitUser(uscId?: string, name?: string, email?: string) {
        datadogRum.setUser({
            id: uscId,
            name,
            email: email
        });
    }

    public debug(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadogLogs.logger.debug(message, context);
        }
    }

    public info(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadogLogs.logger.info(message, context);
        }
    }

    public warn(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadogLogs.logger.warn(message, context);
        }
    }

    public error(message: string, context?: { [x: string]: any }): void {
        if (this.initialized) {
            datadogLogs.logger.error(message, context);
        }
    }
}
