import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { AlertService } from "@app/core/services/alert.service";
import { TermsService } from "@app/core/services/terms.service";
import { ToastNotificationService } from "@app/core/services/toast-notification.service";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { ConfigurationActions } from "@modules/admin/semesters/configuration/store/configurations-action-types";
import { TermActions } from "@modules/main/homepage/store/terms/terms-action-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, mergeMap, of } from "rxjs";

@Injectable()
export class TermsEffects {

  constructor(private actions$: Actions,
              private termsService: TermsService,
              private toastNotificationService: ToastNotificationService,
              private alertService: AlertService,
              private matDialog: MatDialog,
              private snackBar: MatSnackBar) {
  }

  //#region READ/LOAD
  loadActiveTerms$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(TermActions.loadActive),
        concatMap(action => this.termsService.getActive().pipe(
          map(terms => {
              return TermActions.activeTermsInserted({terms});
            },
          ),
          catchError(error => {
            return of(TermActions.loadActiveFailure({
              error: error
            }))
          })
        )),
      )
  );

  loadAllTerms$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(TermActions.loadAll),
        concatMap(action => this.termsService.getAll().pipe(
            map(terms => {
              return TermActions.allTermsInserted({terms});
            }),
            catchError(error => {
              return of(TermActions.loadAllFailure({
                error: error
              }))
            })
          ),
        ))
  );

  loadSelectedTerm$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(TermActions.loadSelectedTerm),
        concatMap(action => this.termsService.loadSelectedTerm(action.termCode)),
        map(selectedTerm => {
            return TermActions.updateSelectedTerm({selectedTerm});
          },
        )
      )
  );

  refreshTermByTermCode$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(TermActions.refreshTermByTermCode),
        concatMap(action => this.termsService.getByTermCode(action.termCode)),
        map(term => {
            return TermActions.refreshSuccess({term});
          },
        )
      )
  );


  //#endregion

  //#region CREATE
  createTerm$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.createTerm),
    map(action => action),
    mergeMap(action => this.termsService.add(action.newTerm)
      .pipe(map(res => TermActions.createSuccess({term: res, dialogId: action.dialogId})),
        catchError(error => of(TermActions.createFailure({
          term: action.newTerm,
          error: JSON.parse(error.response) as ApiResponseErrorModel
        })))
      )
    )
  ));

  handleErrorCreating$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.createFailure),
    map(action => {
      const term = action.term;
      const message = `There was an error creating ${ term.season } ${ term.year }. Please try again shortly.`;
      this.alertService.error(message);

      // todo do we need this anymore? talk with Design
      const snackBarConfig = new MatSnackBarConfig();

      snackBarConfig.verticalPosition = 'top';
      snackBarConfig.horizontalPosition = 'center';
      snackBarConfig.panelClass = "create-semester-snackbar-container";
      //Todo: Make the snackbar a component for more styling options.
      this.snackBar.open(action.error.detail!, "OK", snackBarConfig);

      console.log('Error Creating Term: ', action.error.detail);
    })
  ), {dispatch: false});

  handleSuccessCreating$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.createSuccess),
    map(action => {
      const term = action.term;

      const title = `Configuration for ${ term.seasonName } ${ term.year } has been saved`
      const message = `Course scheduling remains to be done in SIS.`;
      this.alertService.success(message, title);

      const dialog = this.matDialog.getDialogById(action.dialogId);
      dialog?.close(action.term);
    }),
  ), {dispatch: false});
  //#endregion

  //#region DELETE
  deleteTerm$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.deleteTerm),
    map(action => action.term),
    mergeMap(term => this.termsService.delete(term.termCode)
      .pipe(map(res => TermActions.deleteSuccess({term})),
        catchError(error => {
          return of(TermActions.deleteFailure({
            term: term,
            error: JSON.parse(error.response) as ApiResponseErrorModel
          }))
        })
      )
    )
  ));

  handleErrorDeleting$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.deleteFailure),
    map(action => {
      const term = action.term;
      const message = `There was an error deleting ${ term.seasonName } ${ term.year }. Please try again shortly.`;
      this.alertService.error(message);
      console.log('Error Deleting Term: ', action.error.detail);
    }),
  ), {dispatch: false});

  handleSuccessDeleting$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.deleteSuccess),
    map(action => {
        const term = action.term;
        const message = `${ term.seasonName } ${ term.year } has been deleted.`;
        this.toastNotificationService.informational(message);

        return ConfigurationActions.removeConfigurationByTermCode({
          termCode: action.term.termCode
        })
      }
    )));
  //#endregion

  //#region UPDATE
  updateTerm$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.updateTerm),
    map(action => action),
    mergeMap(action => this.termsService.update(action.term)
      .pipe(map(res => TermActions.updateSuccess({term: res, dialogId: action.dialogId})),
        catchError(error => {
          return of(TermActions.updateFailure({
            term: action.term,
            error: JSON.parse(error.response) as ApiResponseErrorModel
          }))
        })
      )
    )
  ));

  handleErrorUpdating$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.updateFailure),
    map(action => {
      const term = action.term;
      const message = `There was an error updating ${ term.seasonName } ${ term.year }. Please try again shortly.`;
      this.alertService.error(message);
      console.log('Error Updating Term: ', action.error.detail);
    }),
  ), {dispatch: false});

  handleSuccessUpdating$ = createEffect(() => this.actions$.pipe(
    ofType(TermActions.updateSuccess),
    map(action => {
      const term = action.term;
      const message = `${ term.seasonName } ${ term.year } has been updated.`;
      this.toastNotificationService.informational(message);

      const dialog = this.matDialog.getDialogById(action.dialogId);
      dialog?.close(action.term);
    }),
  ), {dispatch: false});
  //#endregion
}
