import { IConfigurationVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { ConfigurationActions } from "./configurations-action-types";

export interface ConfigurationsState extends EntityState<IConfigurationVM> {
  areConfigurationsLoaded: boolean;
  isConfigurationEditSuccessfullyCompleted: boolean;
  isConfigurationGeAddSuccessfullyCompleted: boolean;
  isConfigurationGeEditSuccessfullyCompleted: boolean;
  errorLoading: ApiResponseErrorModel | undefined;
  errorModifying: ApiResponseErrorModel | undefined;
}

export function selectId(a: IConfigurationVM): number {
  return <number>a.id;
}

export const areConfigurationsLoaded = (state: ConfigurationsState) => state.areConfigurationsLoaded;
export const setIsConfigurationEditSuccessfullyCompleted = (state: ConfigurationsState) => state.isConfigurationEditSuccessfullyCompleted;
export const setIsConfigurationGeAddSuccessfullyCompleted = (state: ConfigurationsState) => state.isConfigurationGeAddSuccessfullyCompleted;
export const setIsConfigurationGeEditSuccessfullyCompleted = (state: ConfigurationsState) => state.isConfigurationGeEditSuccessfullyCompleted;

export const adapter = createEntityAdapter<IConfigurationVM>(
  {
    selectId: selectId
  }
);

export const initialConfigurationsState = adapter.getInitialState({
  areConfigurationsLoaded: false,
  isConfigurationEditSuccessfullyCompleted: false,
  isConfigurationGeAddSuccessfullyCompleted: false,
  isConfigurationGeEditSuccessfullyCompleted: false,
  isConfigurationLoaded: false
});

export const getErrorLoadingMessage = (state: ConfigurationsState) => state.errorLoading;
export const getErrorModifyingMessage = (state: ConfigurationsState) => state.errorModifying;

export const configurationsReducer = createReducer(
  initialConfigurationsState,

  on(ConfigurationActions.configurationInserted, (state, action) =>
    adapter.addOne(action.configuration, {...state})
  ),

  on(ConfigurationActions.loadConfigurationByTermCodeFailure, (state, action) => {
      return {
        ...state,
        errorLoading: action.error
      }
    }
  ),

  on(ConfigurationActions.deleteSchoolSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.deleteSchoolFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.deleteProgramSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.deleteProgramFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.addSchoolOrProgramSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state,
      isConfigurationEditSuccessfullyCompleted: true
    })),

  on(ConfigurationActions.addSchoolOrProgramFailure, (state, action) => {
      return {
        ...state,
        isConfigurationEditSuccessfullyCompleted: false,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.editProgramSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state,
      isConfigurationEditSuccessfullyCompleted: true
    })),

  on(ConfigurationActions.editProgramFailure, (state, action) => {
      return {
        ...state,
        isConfigurationEditSuccessfullyCompleted: false,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.addGeSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state,
      isConfigurationGeAddSuccessfullyCompleted: true
    })),

  on(ConfigurationActions.addGeFailure, (state, action) => {
      return {
        ...state,
        isConfigurationGeAddSuccessfullyCompleted: false,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.editGeSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state,
      isConfigurationGeEditSuccessfullyCompleted: true
    })),

  on(ConfigurationActions.editGeFailure, (state, action) => {
      return {
        ...state,
        isConfigurationGeEditSuccessfullyCompleted: false,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.deleteGeRequirementSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.deleteGeRequirementFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.deleteGeCategorySuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.deleteGeCategoryFailure, (state, action) => {
      return {
        ...state,
        errorModifying: action.error
      }
    }
  ),

  on(ConfigurationActions.updateSchoolSortOrdersSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.updateProgramSortOrdersSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.updateGeRequirementSortOrdersSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.updateGeCategorySortOrdersSuccess, (state, action) =>
    adapter.setOne(action.configuration, {
      ...state
    })),

  on(ConfigurationActions.setIsConfigurationEditSuccessfullyCompleted, (state, action) => {
      return {
        ...state,
        isConfigurationEditSuccessfullyCompleted: action.value,
      }
    }
  ),

  on(ConfigurationActions.setIsConfigurationGeAddSuccessfullyCompleted, (state, action) => {
      return {
        ...state,
        isConfigurationGeAddSuccessfullyCompleted: action.value,
      }
    }
  ),

  on(ConfigurationActions.setIsConfigurationGeEditSuccessfullyCompleted, (state, action) => {
      return {
        ...state,
        isConfigurationGeEditSuccessfullyCompleted: action.value,
      }
    }
  ),

  on(ConfigurationActions.removeConfiguration, (state, action) =>
    adapter.removeOne(action.configuration.id, {
      ...state
    })
  ),

  on(ConfigurationActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
