import { ACTIVE_TERMS } from "./active-terms.constant";
import { ARCHIVED_TERMS } from "./archived-terms.constant";
import { CourseMode } from "./course-mode.model";
import { DEPARTMENTS } from "./department.model";
import { SectionModel, SECTIONS } from "./section.model";

export class CourseModel implements ICourseModel {
  courseId?: number;
  sisCatalogId?: string | undefined;
  prefix?: string | undefined;
  classNumber?: string | undefined;
  sequence?: string | undefined;
  suffix?: string | undefined;
  crossListedFlag?: string | undefined;
  publishedCourseId?: string | undefined;
  scheduledCourseId?: string | undefined;
  courseTitle?: string | undefined;
  description?: string | undefined;
  courseNotes?: string | undefined;
  departmentCode?: string | undefined;
  seats?: number;
  numberRegistered?: number;
  spacesAvailable?: number;
  isFull?: boolean;
  isClosed?: boolean;
  isCanceled?: boolean;
  levelOnePrefix?: string | undefined;
  levelTwoPrefix?: string | undefined;
  sections?: SectionModel[] | undefined;
  //sisSqNumber?: number;
  // restrictClasses?: CourseRestrictClass[] | undefined;
  // restrictMajors?: CourseRestrictMajor[] | undefined;
  // restrictSchools?: CourseRestrictSchool[] | undefined;
  // corequisites?: SimplifiedCourseModel[] | undefined;
  // prerequisites?: SimplifiedCourseModel[] | undefined;
  courseModes?: CourseMode[] | undefined;
  termCode?: number;
  units?: number;

  constructor(data?: ICourseModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.courseId = _data["courseId"];
      this.sisCatalogId = _data["sisCatalogId"];
      this.prefix = _data["prefix"];
      this.classNumber = _data["classNumber"];
      this.sequence = _data["sequence"];
      this.suffix = _data["suffix"];
      this.crossListedFlag = _data["crossListedFlag"];
      this.publishedCourseId = _data["publishedCourseId"];
      this.scheduledCourseId = _data["scheduledCourseId"];
      this.courseTitle = _data["courseTitle"];
      this.description = _data["description"];
      this.courseNotes = _data["courseNotes"];
      this.departmentCode = _data["departmentCode"];
      this.seats = _data["seats"];
      this.units = _data["units"];
      this.numberRegistered = _data["numberRegistered"];
      this.spacesAvailable = _data["spacesAvailable"];
      // this.isFull = _data["isFull"];
      // this.isClosed = _data["isClosed"];
      // this.isCanceled = _data["isCanceled"];
      // this.levelOnePrefix = _data["levelOnePrefix"];
      // this.levelTwoPrefix = _data["levelTwoPrefix"];
      if (Array.isArray(_data["sections"])) {
        this.sections = [] as any;
        for (let item of _data["sections"])
          this.sections!.push(SectionModel.fromJS(item));
      }
      //this.sisSqNumber = _data["sisSqNumber"];
      // if (Array.isArray(_data["restrictClasses"])) {
      //   this.restrictClasses = [] as any;
      //   for (let item of _data["restrictClasses"])
      //     this.restrictClasses!.push(CourseRestrictClass.fromJS(item));
      // }
      // if (Array.isArray(_data["restrictMajors"])) {
      //   this.restrictMajors = [] as any;
      //   for (let item of _data["restrictMajors"])
      //     this.restrictMajors!.push(CourseRestrictMajor.fromJS(item));
      // }
      // if (Array.isArray(_data["restrictSchools"])) {
      //   this.restrictSchools = [] as any;
      //   for (let item of _data["restrictSchools"])
      //     this.restrictSchools!.push(CourseRestrictSchool.fromJS(item));
      // }
      // if (Array.isArray(_data["corequisites"])) {
      //   this.corequisites = [] as any;
      //   for (let item of _data["corequisites"])
      //     this.corequisites!.push(SimplifiedCourseModel.fromJS(item));
      // }
      // if (Array.isArray(_data["prerequisites"])) {
      //   this.prerequisites = [] as any;
      //   for (let item of _data["prerequisites"])
      //     this.prerequisites!.push(SimplifiedCourseModel.fromJS(item));
      // }
      if (Array.isArray(_data["courseModes"])) {
        this.courseModes = [] as any;
        for (let item of _data["courseModes"])
          this.courseModes!.push(CourseMode.fromJS(item));
      }
    }
  }

  static fromJS(data: any): CourseModel {
    data = typeof data === "object" ? data : {};
    let result = new CourseModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === "object" ? data : {};
    data["courseId"] = this.courseId;
    data["sisCatalogId"] = this.sisCatalogId;
    data["prefix"] = this.prefix;
    data["classNumber"] = this.classNumber;
    data["sequence"] = this.sequence;
    data["suffix"] = this.suffix;
    data["crossListedFlag"] = this.crossListedFlag;
    data["publishedCourseId"] = this.publishedCourseId;
    data["scheduledCourseId"] = this.scheduledCourseId;
    data["courseTitle"] = this.courseTitle;
    data["description"] = this.description;
    data["courseNotes"] = this.courseNotes;
    data["departmentCode"] = this.departmentCode;
    data["seats"] = this.seats;
    data["units"] = this.units;
    data["numberRegistered"] = this.numberRegistered;
    data["spacesAvailable"] = this.spacesAvailable;
    data["isFull"] = this.isFull;
    data["isClosed"] = this.isClosed;
    data["isCanceled"] = this.isCanceled;
    data["levelOnePrefix"] = this.levelOnePrefix;
    data["levelTwoPrefix"] = this.levelTwoPrefix;
    if (Array.isArray(this.sections)) {
      data["sections"] = [];
      for (let item of this.sections)
        data["sections"].push(item.toJSON());
    }
    //data["sisSqNumber"] = this.sisSqNumber;
    // if (Array.isArray(this.restrictClasses)) {
    //   data["restrictClasses"] = [];
    //   for (let item of this.restrictClasses)
    //     data["restrictClasses"].push(item.toJSON());
    // }
    // if (Array.isArray(this.restrictMajors)) {
    //   data["restrictMajors"] = [];
    //   for (let item of this.restrictMajors)
    //     data["restrictMajors"].push(item.toJSON());
    // }
    // if (Array.isArray(this.restrictSchools)) {
    //   data["restrictSchools"] = [];
    //   for (let item of this.restrictSchools)
    //     data["restrictSchools"].push(item.toJSON());
    // }
    // if (Array.isArray(this.corequisites)) {
    //   data["corequisites"] = [];
    //   for (let item of this.corequisites)
    //     data["corequisites"].push(item.toJSON());
    // }
    // if (Array.isArray(this.prerequisites)) {
    //   data["prerequisites"] = [];
    //   for (let item of this.prerequisites)
    //     data["prerequisites"].push(item.toJSON());
    // }
    if (Array.isArray(this.courseModes)) {
      data["courseModes"] = [];
      for (let item of this.courseModes)
        data["courseModes"].push(item.toJSON());
    }
    return data;
  }

  static createFake(departmentCode: string, termCode: number, courseIndex: number): CourseModel {
    const prefix: string = `${departmentCode}`;
    const courseLevel: number = getRandom(1, 4);
    const classNumber: string = `${courseLevel * 100}`;
    const seats: number = getRandom(1, 50);

    return new CourseModel({
      prefix: prefix,
      description: classNumber + " Description",
      departmentCode: departmentCode,
      levelOnePrefix: departmentCode,
      levelTwoPrefix: departmentCode,
      courseId: parseInt(`${termCode}${courseIndex}`),
      classNumber: classNumber,
      sequence: undefined,
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: prefix + "-" + classNumber,
      scheduledCourseId: prefix + "-" + classNumber,
      courseTitle: "Title " + classNumber,
      courseNotes: " ",
      seats: seats,
      numberRegistered: undefined,
      spacesAvailable: getRandom(1, seats),
      termCode: termCode,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === parseInt(`${termCode}${courseIndex}`)).map(s => new SectionModel(s)),
    },
    );
  }

  static createCourses(termCode: number): ICourseModel[] {
    let count = 0;

    const f = [
      ...DEPARTMENTS.map(d => CourseModel.createFake(d.prefix!, termCode!, ++count)),
      ...DEPARTMENTS.map(d => CourseModel.createFake(d.prefix!, termCode!, ++count)),
      ...DEPARTMENTS.map(d => CourseModel.createFake(d.prefix!, termCode!, ++count)),
    ]
    return [...f];
  }
}

//filter(d => d.schoolPrefix !== "FA" && d.prefix !== "ART")

export interface ICourseModel {
  courseId?: number;
  sisCatalogId?: string | undefined;
  prefix?: string | undefined;
  classNumber?: string | undefined;
  sequence?: string | undefined;
  suffix?: string | undefined;
  crossListedFlag?: string | undefined;
  publishedCourseId?: string | undefined;
  scheduledCourseId?: string | undefined;
  courseTitle?: string | undefined;
  description?: string | undefined;
  courseNotes?: string | undefined;
  departmentCode?: string | undefined;
  seats?: number;
  numberRegistered?: number;
  spacesAvailable?: number;
  isFull?: boolean;
  isClosed?: boolean;
  isCanceled?: boolean;
  levelOnePrefix?: string | undefined;
  levelTwoPrefix?: string | undefined;
  sections?: SectionModel[] | undefined;
  sisSqNumber?: number;
  // restrictClasses?: CourseRestrictClass[] | undefined;
  // restrictMajors?: CourseRestrictMajor[] | undefined;
  // restrictSchools?: CourseRestrictSchool[] | undefined;
  // corequisites?: SimplifiedCourseModel[] | undefined;
  // prerequisites?: SimplifiedCourseModel[] | undefined;
  courseModes?: CourseMode[] | undefined;
  termCode?: number;
  units?: number;
}

function getRandom(min: number, max: number): number { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const COURSES: ICourseModel[] = [
  ...[
    new CourseModel({
      prefix: "ART",
      description: "Art History",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23301,
      classNumber: "123XG",
      sequence: "XG",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-123XG",
      scheduledCourseId: "ART-123XG",
      courseTitle: "Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23301).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Art History",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23321,
      classNumber: "223XG",
      sequence: "XG",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-223XG",
      scheduledCourseId: "ART-223XG",
      courseTitle: "Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23321).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Art History",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23331,
      classNumber: "323XG",
      sequence: "XG",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-323XG",
      scheduledCourseId: "ART-323XG",
      courseTitle: "Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23301).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Art History",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23341,
      classNumber: "423XG",
      sequence: "XG",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-423XG",
      scheduledCourseId: "ART-423XG",
      courseTitle: "Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23301).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Creating and Understanding Visuals",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23302,
      classNumber: "141g",
      sequence: "g",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-141g",
      scheduledCourseId: "ART-141g",
      courseTitle: "Creating and Understanding Visuals",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23302).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Drawing for Art and Design",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23303,
      classNumber: "156",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-156",
      scheduledCourseId: "ART-156",
      courseTitle: "Drawing for Art and Design",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23303).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "An art description that's a little flat.",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23300,
      classNumber: "207",
      sequence: "A",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-207A",
      scheduledCourseId: "ART-207A",
      courseTitle: "Two-Dimensional Art",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23300).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Studio Critique",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 23304,
      classNumber: "280",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-280",
      scheduledCourseId: "ART-280",
      courseTitle: "Studio Critique",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23304).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Directed examination of personal approaches to drawing with emphasis on making visual and artistic decisions and developing additional skills and sources of inspiration. Individual expression will be encouraged.",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 25689,
      classNumber: "310",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-310",
      scheduledCourseId: "ART-310",
      courseTitle: "Drawing II",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 2,
      sections: SECTIONS.filter(s => s.courseId === 25689).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "AHIS",
      description: "Methodologies, theories and critical traditions that have shaped the discipline. Emphasis will vary depending on faculty. Required of all first-year MA and PhD candidates.",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "AHIS",
      courseId: 12543,
      classNumber: "500",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "AHIS-500",
      scheduledCourseId: "AHIS-500",
      courseTitle: "Methods and Theory of Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: 15,
      spacesAvailable: 5,
      termCode: 20233,
      units: 1,
      sections: SECTIONS.filter(s => s.courseId === 12543).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "CORE",
      description: "Mediterranean and European art and architecture presented in histori…",
      departmentCode: "CORE",
      levelOnePrefix: "CORE",
      levelTwoPrefix: "CORE",
      courseId: 1001,
      classNumber: "450",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "CORE-450",
      scheduledCourseId: "CORE-450",
      courseTitle: "Communication for Organizations: Exploring Art Creativity",
      courseNotes: " ",
      seats: 23,
      numberRegistered: undefined,
      spacesAvailable: 23,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 1001).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "CRIT",
      description: "Ethics in the 21st Century: Business, Politics & the art of Technology",
      departmentCode: "CRIT",
      levelOnePrefix: "CRIT",
      levelTwoPrefix: "CRIT",
      courseId: 1002,
      classNumber: "105",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "CRIT-105",
      scheduledCourseId: "CRIT-105",
      courseTitle: "Social and Racial Injustice in the U.S.",
      courseNotes: " ",
      seats: 24,
      numberRegistered: undefined,
      spacesAvailable: 24,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 1002).map(s => new SectionModel(s))
    }),

    //2022
    new CourseModel({
      prefix: "ART",
      description: "Art History",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 22301,
      classNumber: "123XG",
      sequence: "XG",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-132XG",
      scheduledCourseId: "ART-123XG",
      courseTitle: "Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20223,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 22301).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Creating and Understanding Visuals",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 22302,
      classNumber: "141g",
      sequence: "g",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-141g",
      scheduledCourseId: "ART-141g",
      courseTitle: "Creating and Understanding Visuals",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20233,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 23302).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Drawing for Art and Design",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 22303,
      classNumber: "156",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-156",
      scheduledCourseId: "ART-156",
      courseTitle: "Drawing for Art and Design",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20223,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 22303).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "An art description that's a little flat.",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 22300,
      classNumber: "207",
      sequence: "A",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-207A",
      scheduledCourseId: "ART-207A",
      courseTitle: "Two-Dimensional Art",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20223,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 22300).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Studio Critique",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 22304,
      classNumber: "280",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-280",
      scheduledCourseId: "ART-280",
      courseTitle: "Studio Critique",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20223,
      units: 4,
      sections: SECTIONS.filter(s => s.courseId === 22304).map(s => new SectionModel(s))
    }),
    new CourseModel({
      prefix: "ART",
      description: "Directed examination of personal approaches to drawing with emphasis on making visual and artistic decisions and developing additional skills and sources of inspiration. Individual expression will be encouraged.",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "ART",
      courseId: 22689,
      classNumber: "310",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "ART-310",
      scheduledCourseId: "ART-310",
      courseTitle: "Drawing II",
      courseNotes: " ",
      seats: 20,
      numberRegistered: undefined,
      spacesAvailable: 5,
      termCode: 20223,
      units: 2,
      sections: SECTIONS.filter(s => s.courseId === 22689).map(s => new SectionModel(s))
    }),

    new CourseModel({
      prefix: "AHIS",
      description: "Methodologies, theories and critical traditions that have shaped the discipline. Emphasis will vary depending on faculty. Required of all first-year MA and PhD candidates.",
      departmentCode: "FAHI",
      levelOnePrefix: "FAHI",
      levelTwoPrefix: "AHIS",
      courseId: 11543,
      classNumber: "500",
      sequence: "",
      suffix: " ",
      crossListedFlag: "N",
      publishedCourseId: "AHIS-500",
      scheduledCourseId: "AHIS-500",
      courseTitle: "Methods and Theory of Art History",
      courseNotes: " ",
      seats: 20,
      numberRegistered: 15,
      spacesAvailable: 5,
      termCode: 20223,
      units: 1,
      sections: SECTIONS.filter(s => s.courseId === 11543).map(s => new SectionModel(s))
    }),

    
  ],

  ...ACTIVE_TERMS.map(t => t.termCode)
    .map((termCode) => CourseModel.createCourses(termCode!)).flat(),

  ...ARCHIVED_TERMS.map(t => t.termCode)
    .map((termCode) => CourseModel.createCourses(termCode!)).flat(),
];
