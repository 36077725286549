import { ICoursePrefixVM } from "@app/core/services/WebRegApiClient";
import { CoursePrefixActions } from "@app/store/coursePrefixes/coursePrefixes-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface CoursePrefixesState extends EntityState<ICoursePrefixVM> {
  areCoursePrefixesLoaded: boolean;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: ICoursePrefixVM): string {
  return <string>a.prefix;
}

export const areCoursePrefixesLoaded = (state: CoursePrefixesState) => state.areCoursePrefixesLoaded;

export const adapter = createEntityAdapter<ICoursePrefixVM>(
  {
    selectId: selectId
  }
);

export const initialCoursePrefixesState = adapter.getInitialState({
  areCoursePrefixesLoaded: false
});

export const getErrorMessage = (state: CoursePrefixesState) => state.error;

export const coursePrefixesReducer = createReducer(
  initialCoursePrefixesState,

  on(CoursePrefixActions.coursePrefixesInserted, (state, action) =>
    adapter.addMany(action.coursePrefixes, {...state, areCoursePrefixesLoaded: true})
  ),

  on(CoursePrefixActions.loadAllFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(CoursePrefixActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
