import { IProgramDetailsVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from '@models/api-response-error.model';
import { createAction, props } from "@ngrx/store";

export const loadProgramDetailsByPrefix = createAction(
  "[Program Details Actions] Load Program Details For Program",
  props<{ termCode: number, schoolCode: string, prefix: string }>()
);

export const programDetailsInsertedForProgram = createAction(
  "[Program Details Actions] ProgramDetails Successfully Loaded For Program",
  props<{ programDetails: IProgramDetailsVM, prefix: string }>()
);

export const clearError = createAction(
  "[Program Details Actions] Clear ProgramDetails Error"
);

export const loadProgramDetailsFailure = createAction(
  "[Program Details Effect] Load ProgramDetails Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);
