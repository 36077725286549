import { ITermAddVM, ITermCreateVM, ITermVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadAll = createAction(
  "[Admin] Load All Terms"
);

export const loadAllFailure = createAction(
  "[Terms Effect] Load All Terms Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const loadActive = createAction(
  "[Terms Effect] Load Active Terms"
);

export const loadActiveFailure = createAction(
  "[Terms Effect] Load Active Terms Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const loadSelectedTerm = createAction(
  "[Selected Term Resolver] Load Selected Term",
  props<{ termCode: number | null }>()
);

export const refreshTermByTermCode = createAction(
  "[Term Actions] Refresh By Term Code",
  props<{ termCode: number | null }>()
);

export const refreshSuccess = createAction(
  "[Term Actions] Term Successfully Refreshed",
  props<{ term: ITermVM }>()
);

export const updateSelectedTerm = createAction(
  "[Select Term] Current Selected Term Updated",
  props<{ selectedTerm: ITermVM }>()
);

export const updateNavSelectedTerm = createAction(
  "[Select Term In Nav] Current Nav-Selected Term Updated",
  props<{ navSelectedTerm: ITermVM }>()
);

export const activeTermsInserted = createAction(
  "[Terms Effect] Active Terms Loaded",
  props<{ terms: ITermVM[] }>()
);

export const allTermsInserted = createAction(
  "[Terms Effect] All Terms Loaded",
  props<{ terms: ITermVM[] }>()
);

// CREATE
export const createTerm = createAction(
  "[Create Term] Create Term",
  props<{ newTerm: ITermAddVM, dialogId: string }>()
);

export const createSuccess = createAction(
  "[Terms] Create Success", props<{ term: ITermVM, dialogId: string }>()
);

export const createFailure = createAction(
  "[Terms] Create Failure", props<{ term: ITermCreateVM, error: ApiResponseErrorModel }>()
);

// UPDATE
export const updateTerm = createAction(
  "[Admin Terms] Update Term",
  props<{ term: ITermVM, dialogId: string }>()
);

export const updateSuccess = createAction(
  "[Admin Terms] Term Successfully Updated",
  props<{ term: ITermVM, dialogId: string }>()
);

export const updateConfigurationSuccess = createAction(
  "[Admin Terms] Term Successfully Updated",
  props<{ term: ITermVM }>()
);

export const updateFailure = createAction(
  "[Admin Terms] Update Failure",
  props<{ term: ITermVM, error: ApiResponseErrorModel }>()
);

// DELETE
export const deleteTerm = createAction(
  "[Admin Terms] Delete Term",
  props<{ term: ITermVM }>()
);

export const deleteSuccess = createAction(
  "[Admin Terms] Term Successfully Deleted",
  props<{ term: ITermVM }>()
);

export const deleteFailure = createAction(
  "[Admin Terms] Delete Failure",
  props<{ term: ITermVM, error: ApiResponseErrorModel }>()
);

export const clearModifyingError = createAction(
  "[Term Actions] Clear Modifying Terms Error"
);

export const clearLoadingError = createAction(
  "[Term Actions] Clear Loading Terms Error"
);
