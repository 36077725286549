export function getMinutesFromTime24(timeString: string): number {
  const [hours, minutes] = timeString.split(':').map(Number);
  return hours * 60 + minutes;
};

export function getTime24FromMinutes(totalMinutes: number): string {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
};

export function convert24HourTo12Hour(time: string): string {
  const timeArray = time.split(':');
  const hours24 = parseInt(timeArray[0], 10);
  const minutes = parseInt(timeArray[1], 10);

  const period = (hours24 < 12 || hours24 === 24) ? 'AM' : 'PM';
  const hours12 = hours24 <= 12 ? hours24 : (hours24 - 12);

  return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
};
