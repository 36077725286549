<div id="skipLinksContainer">
    <a
      role="link"
      aria-label="Skip to main content"
      aria-hidden="true"
      class="skip-link"
      [routerLink]="[skipLinkUrl]"
      [fragment]="'main'">Skip to main content </a>
  </div>

  <router-outlet></router-outlet>
