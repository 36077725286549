import { ISchoolVM } from "@app/core/services/WebRegApiClient";
import { SchoolActions } from "@app/store/schools/schools-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { TermActions } from "@modules/main/homepage/store/terms/terms-action-types";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface SchoolsState extends EntityState<ISchoolVM> {
  areSchoolsLoadedForTerm: Record<number, boolean>;
  error: ApiResponseErrorModel | string | undefined;
}

export function selectId(a: ISchoolVM): string {
  return <string>a.prefix + <number>a.termCode;
}

export const areSchoolsLoadedForTerm = (state: SchoolsState, termCode: number) => state.areSchoolsLoadedForTerm[termCode];

export const adapter = createEntityAdapter<ISchoolVM>(
  {
    selectId: selectId
  }
);

export const initialSchoolsState = adapter.getInitialState({
  areSchoolsLoadedForTerm: {}
});

export const getErrorMessage = (state: SchoolsState) => state.error;

export const schoolsReducer = createReducer(
  initialSchoolsState,

  on(SchoolActions.loadSchoolsByTermCodeSuccess, (state, action) =>
    adapter.addMany(action.schools, {
      ...state, areSchoolsLoadedForTerm: {
        ...state.areSchoolsLoadedForTerm,
        [action.termCode]: true
      }
    })
  ),

  on(SchoolActions.loadSchoolsByTermCodeFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(TermActions.deleteSuccess, (state, action) => {
      return {
        ...state,
        areSchoolsLoadedForTerm: {
          ...state.areSchoolsLoadedForTerm,
          [action.term.termCode]: false
        }
      }
    }
  ),

  on(SchoolActions.removeSchoolsByTermCode, (state, action) =>
    adapter.removeMany(action.schools, {
      ...state, areSchoolsLoadedForTerm: {
        ...state.areSchoolsLoadedForTerm,
        [action.termCode]: false
      }
    })
  ),

  on(SchoolActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
