export const environment = {
  name:"prod",
  production: true,
  apiBaseUrl: "https://beta-classes.usc.edu",
  apiBaseHref: "/",
  hasDatadog: true,
  datadog: {
    applicationId: '934f2b79-bc4d-4f7d-b12a-18d9923429e8',
    clientToken: 'pub9cb89f86f5d31f2cdc8c806b8b39d869',
    site: 'datadoghq.com',
    service: 'schedule-of-classes-ui',
    env: 'prod',
    allowedTracingUrls: ["https://api.usc.edu/registration"],
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  }
};
