export class CourseMode implements ICourseMode {
  rnrModeCode?: string | undefined;
  rnrModeHours?: string | undefined;
  courseId?: number;

  constructor(data?: ICourseMode) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.rnrModeCode = _data["rnrModeCode"];
      this.rnrModeHours = _data["rnrModeHours"];
      this.courseId = _data["courseId"];
    }
  }

  static fromJS(data: any): CourseMode {
    data = typeof data === 'object' ? data : {};
    let result = new CourseMode();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["rnrModeCode"] = this.rnrModeCode;
    data["rnrModeHours"] = this.rnrModeHours;
    data["courseId"] = this.courseId;
    return data;
  }
}

export interface ICourseMode {
  rnrModeCode?: string | undefined;
  rnrModeHours?: string | undefined;
  courseId?: number;
}
