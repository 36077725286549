import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminAuthGuard } from "@app/core/guards/admin-auth-guard";

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AdminAuthGuard],
    canActivateChild: [AdminAuthGuard],
    loadChildren: () => import("@modules/admin/admin.module").then(m => m.AdminModule)
  },
  {
    path: '',
    loadChildren: () => import("@modules/main/main.module").then(m => m.MainModule)
  },
  {
    // Should this be a 404?
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    paramsInheritanceStrategy: 'always'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
