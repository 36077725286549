import { IProgramVM } from "@app/core/services/WebRegApiClient";
import { ProgramActions } from "@app/store/programs/programs-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { TermActions } from "@modules/main/homepage/store/terms/terms-action-types";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface ProgramsState extends EntityState<IProgramVM> {
  areProgramsLoaded: boolean;
  areProgramsLoadedForTerm: Record<number, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: IProgramVM): string {
  return <string>a.prefix + <number>a.termCode;
}

export const areProgramsLoaded = (state: ProgramsState) => state.areProgramsLoaded;
export const areProgramsLoadedForTerm = (state: ProgramsState, termCode: number) => state.areProgramsLoadedForTerm[termCode];

export const adapter = createEntityAdapter<IProgramVM>(
  {
    selectId: selectId
  }
);

export const initialProgramsState = adapter.getInitialState({
  areProgramsLoaded: false,
  areProgramsLoadedForTerm: {}
});

export const getErrorMessage = (state: ProgramsState) => state.error;

export const programsReducer = createReducer(
  initialProgramsState,

  on(ProgramActions.programsInserted, (state, action) =>
    adapter.addMany(action.programs, {...state, areProgramsLoaded: true})
  ),

  on(ProgramActions.programsInsertedForTerm, (state, action) =>
    adapter.addMany(action.programs, {
      ...state, areProgramsLoadedForTerm: {
        ...state.areProgramsLoadedForTerm,
        [action.termCode]: true
      }
    })),

  on(ProgramActions.loadProgramsByTermCodeFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(TermActions.deleteSuccess, (state, action) => {
      return {
        ...state,
        areProgramsLoadedForTerm: {
          ...state.areProgramsLoadedForTerm,
          [action.term.termCode]: false
        }
      }
    }
  ),

  on(ProgramActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
