import { IProgramCoursesVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadProgramCourseList = createAction(
  "[Program Course List Actions] Load ProgramCourseList",
  props<{ termCode: number, schoolCode: string, programCode: string }>()
);

export const loadProgramCourseListSuccess = createAction(
  "[Program Course List Effect] ProgramCourseList Successfully Loaded",
  props<{ programCourseList: IProgramCoursesVM, concatenatedKey: string }>()
);

export const loadProgramCourseListFailure = createAction(
  "[Program Course List Effect] Load ProgramCourseList Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const clearError = createAction(
  "[Program Course List Actions] Clear ProgramCourseList Error"
);
