import { IPeConfigurableSessionVM } from "@app/core/services/WebRegApiClient";
import {
  PeConfigurableSessionActions
} from "@app/store/pe-configurable-sessions/pe-configurable-sessions-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { TermActions } from "@modules/main/homepage/store/terms/terms-action-types";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface PeConfigurableSessionsState extends EntityState<IPeConfigurableSessionVM> {
  arePeConfigurableSessionsLoadedForTerm: Record<number, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: IPeConfigurableSessionVM): string {
  return <string>a.sessionCode + <string>a.termCode;
}

export const arePeConfigurableSessionsLoadedForTerm = (state: PeConfigurableSessionsState, termCode: number) => state.arePeConfigurableSessionsLoadedForTerm[termCode];

export const adapter = createEntityAdapter<IPeConfigurableSessionVM>(
  {
    selectId: selectId
  }
);

export const initialPeConfigurableSessionsState = adapter.getInitialState({
  arePeConfigurableSessionsLoadedForTerm: {}
});

export const getErrorMessage = (state: PeConfigurableSessionsState) => state.error;

export const peConfigurableSessionsReducer = createReducer(
  initialPeConfigurableSessionsState,

  on(PeConfigurableSessionActions.sessionsInsertedForTerm, (state, action) =>
    adapter.addMany(action.sessions, {
      ...state, arePeConfigurableSessionsLoadedForTerm: {
        ...state.arePeConfigurableSessionsLoadedForTerm,
        [action.termCode]: true
      },
    })
  ),

  on(PeConfigurableSessionActions.loadAllFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(TermActions.deleteSuccess, (state, action) => {
      return {
        ...state,
        arePeConfigurableSessionsLoadedForTerm: {
          ...state.arePeConfigurableSessionsLoadedForTerm,
          [action.term.termCode]: false
        }
      }
    }
  ),

  on(PeConfigurableSessionActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
