import { IPeSession } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import { SessionActions } from "@app/store/sessions/sessions-action-types";

export interface SessionsState extends EntityState<IPeSession> {
  isSessionLoaded: Record<number, boolean>;
  error: ApiResponseErrorModel | undefined;
}

export function selectId(a: IPeSession): number {
  return <number>a.rnrSessionId;
}

export const isSessionLoaded = (state: SessionsState, rnrSessionId: number) => state.isSessionLoaded[rnrSessionId];

export const adapter = createEntityAdapter<IPeSession>(
  {
    selectId: selectId
  }
);

export const initialSessionsState = adapter.getInitialState({
  isSessionLoaded: {}
});

export const getErrorMessage = (state: SessionsState) => state.error;

export const sessionsReducer = createReducer(
  initialSessionsState,

  on(SessionActions.sessionInserted, (state, action) =>
    adapter.addOne(action.session, {
      ...state, isSessionLoaded: {
        ...state.isSessionLoaded,
        [action.rnrSessionId]: true
      },
    })
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
