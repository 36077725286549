import { createReducer, on } from "@ngrx/store";
import { AppTitleState } from "./app-title.state";
import { AppTitleActions } from "./app-title-action-types";
import { Title } from "@angular/platform-browser";
import { createEntityAdapter } from "@ngrx/entity";

const initialState:AppTitleState = {
    title: 'Loading...'
}

export const adapter = createEntityAdapter(  );

export const getTitle = (state: AppTitleState) => state.title;

export const appTitleReducer = createReducer(
    initialState,

    on(AppTitleActions.updateAppTitle, (state, action) =>({
        ...state,
        title:action.title
    })),
)