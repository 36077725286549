import {
  ConfigurationAddGeVM,
  ConfigurationAddSchoolOrProgramVM,
  ConfigurationDeleteGeCategoryVM,
  ConfigurationDeleteGeRequirementVM,
  ConfigurationDeleteProgramVM,
  ConfigurationDeleteSchoolVM,
  ConfigurationEditGeVM,
  ConfigurationEditProgramVM,
  ConfigurationUpdateGeCategorySortOrdersVM,
  ConfigurationUpdateProgramSortOrdersVM,
  ConfigurationVM,
  IConfigurationVM
} from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from '@models/api-response-error.model';
import { createAction, props } from "@ngrx/store";

export const loadAll = createAction(
  "[Admin] Load All Configurations"
);

export const configurationInserted = createAction(
  "[Configurations Effect] Configuration Loaded",
  props<{ configuration: IConfigurationVM }>()
);

export const loadConfigurationByTermCode = createAction(
  "[Configuration Effect] Load Configuration By Term Code",
  props<{ termCode: number }>()
);

export const loadConfigurationByTermCodeFailure = createAction(
  "[Configuration Effect] Load Configuration By Term Code Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

// CREATE

// UPDATE
export const addSchoolOrProgram = createAction(
  "[Admin Configurations] Add School or Program",
  props<{ model: ConfigurationAddSchoolOrProgramVM }>()
);

export const addSchoolOrProgramSuccess = createAction(
  "[Admin Configurations] Configuration School or Program Successfully Added",
  props<{ model: ConfigurationAddSchoolOrProgramVM, configuration: IConfigurationVM }>()
);

export const addSchoolOrProgramFailure = createAction(
  "[Admin Configurations] Add School or Program Failure",
  props<{ model: ConfigurationAddSchoolOrProgramVM, error: ApiResponseErrorModel }>()
);

export const editProgram = createAction(
  "[Admin Configurations] Edit Program",
  props<{ model: ConfigurationEditProgramVM }>()
);

export const editProgramSuccess = createAction(
  "[Admin Configurations] Configuration Program Successfully Edited",
  props<{ model: ConfigurationEditProgramVM, configuration: IConfigurationVM }>()
);

export const editProgramFailure = createAction(
  "[Admin Configurations] Edit Program Failure",
  props<{ model: ConfigurationEditProgramVM, error: ApiResponseErrorModel }>()
);

export const addGe = createAction(
  "[Admin Configurations] Add Ge",
  props<{ model: ConfigurationAddGeVM }>()
);

export const addGeSuccess = createAction(
  "[Admin Configurations] Configuration Ge Successfully Added",
  props<{ model: ConfigurationAddGeVM, configuration: IConfigurationVM }>()
);

export const addGeFailure = createAction(
  "[Admin Configurations] Add Ge Failure",
  props<{ model: ConfigurationAddGeVM, error: ApiResponseErrorModel }>()
);

export const editGe = createAction(
  "[Admin Configurations] Edit Ge",
  props<{ model: ConfigurationEditGeVM }>()
);

export const editGeSuccess = createAction(
  "[Admin Configurations] Configuration Ge Successfully Edited",
  props<{ model: ConfigurationEditGeVM, configuration: IConfigurationVM }>()
);

export const editGeFailure = createAction(
  "[Admin Configurations] Edit Ge Failure",
  props<{ model: ConfigurationEditGeVM, error: ApiResponseErrorModel }>()
);

export const updateSchoolSortOrders = createAction(
  "[Admin Configurations] Update Sort Order of Schools",
  props<{ configuration: ConfigurationVM }>()
);

export const updateSchoolSortOrdersSuccess = createAction(
  "[Admin Configurations] Successfully updated school sort orders",
  props<{ configuration: IConfigurationVM }>()
);

export const updateSchoolSortOrdersFailure = createAction(
  "[Admin Configurations] Failed to update school sort orders",
  props<{ model: ConfigurationVM, error: ApiResponseErrorModel }>()
);

export const updateProgramSortOrders = createAction(
  "[Admin Configurations] Update Sort Order of Programs",
  props<{ model: ConfigurationUpdateProgramSortOrdersVM }>()
);

export const updateProgramSortOrdersSuccess = createAction(
  "[Admin Configurations] Successfully updated program sort orders",
  props<{ model: ConfigurationUpdateProgramSortOrdersVM, configuration: IConfigurationVM }>()
);

export const updateProgramSortOrdersFailure = createAction(
  "[Admin Configurations] Failed to update program sort orders",
  props<{ model: ConfigurationUpdateProgramSortOrdersVM, error: ApiResponseErrorModel }>()
);

export const updateGeRequirementSortOrders = createAction(
  "[Admin Configurations] Update Sort Order of GE Requirements",
  props<{ configuration: ConfigurationVM }>()
);

export const updateGeRequirementSortOrdersSuccess = createAction(
  "[Admin Configurations] Successfully updated ge requirement sort orders",
  props<{ configuration: IConfigurationVM }>()
);

export const updateGeRequirementSortOrdersFailure = createAction(
  "[Admin Configurations] Failed to update ge requirement sort orders",
  props<{ model: ConfigurationVM, error: ApiResponseErrorModel }>()
);

export const updateGeCategorySortOrders = createAction(
  "[Admin Configurations] Update Sort Order of GE Categories",
  props<{ model: ConfigurationUpdateGeCategorySortOrdersVM }>()
);

export const updateGeCategorySortOrdersSuccess = createAction(
  "[Admin Configurations] Successfully updated ge category sort orders",
  props<{ model: ConfigurationUpdateGeCategorySortOrdersVM, configuration: IConfigurationVM }>()
);

export const updateGeCategorySortOrdersFailure = createAction(
  "[Admin Configurations] Failed to update ge category sort orders",
  props<{ model: ConfigurationUpdateGeCategorySortOrdersVM, error: ApiResponseErrorModel }>()
);

// DELETE

export const removeConfigurationByTermCode = createAction(
  "[Admin Configurations] Remove Configuration By Term Code",
  props<{ termCode: number }>()
);

export const removeConfiguration = createAction(
  "[Admin Configurations] Remove Configuration",
  props<{ configuration: IConfigurationVM }>()
);

export const deleteSchool = createAction(
  "[Admin Configurations] Delete School",
  props<{ model: ConfigurationDeleteSchoolVM }>()
);

export const deleteSchoolSuccess = createAction(
  "[Admin Configurations] Configuration School Successfully Deleted",
  props<{ model: ConfigurationDeleteSchoolVM, configuration: IConfigurationVM }>()
);

export const deleteSchoolFailure = createAction(
  "[Admin Configurations] Delete School Failure",
  props<{ model: ConfigurationDeleteSchoolVM, error: ApiResponseErrorModel }>()
);

export const deleteProgram = createAction(
  "[Admin Configurations] Delete Program",
  props<{ model: ConfigurationDeleteProgramVM }>()
);

export const deleteProgramSuccess = createAction(
  "[Admin Configurations] Configuration Program Successfully Deleted",
  props<{ model: ConfigurationDeleteProgramVM, configuration: IConfigurationVM }>()
);

export const deleteProgramFailure = createAction(
  "[Admin Configurations] Delete Program Failure",
  props<{ model: ConfigurationDeleteProgramVM, error: ApiResponseErrorModel }>()
);

export const deleteGeRequirement = createAction(
  "[Admin Configurations] Delete Ge Requirement",
  props<{ model: ConfigurationDeleteGeRequirementVM }>()
);

export const deleteGeRequirementSuccess = createAction(
  "[Admin Configurations] Configuration Ge Requirement Successfully Deleted",
  props<{ model: ConfigurationDeleteGeRequirementVM, configuration: IConfigurationVM }>()
);

export const deleteGeRequirementFailure = createAction(
  "[Admin Configurations] Delete Ge Requirement Failure",
  props<{ model: ConfigurationDeleteGeRequirementVM, error: ApiResponseErrorModel }>()
);

export const deleteGeCategory = createAction(
  "[Admin Configurations] Delete Ge Category",
  props<{ model: ConfigurationDeleteGeCategoryVM }>()
);

export const deleteGeCategorySuccess = createAction(
  "[Admin Configurations] Configuration Ge Category Successfully Deleted",
  props<{ model: ConfigurationDeleteGeCategoryVM, configuration: IConfigurationVM }>()
);

export const deleteGeCategoryFailure = createAction(
  "[Admin Configurations] Delete Ge Category Failure",
  props<{ model: ConfigurationDeleteGeCategoryVM, error: ApiResponseErrorModel }>()
);

export const setIsConfigurationEditSuccessfullyCompleted = createAction(
  "[Configuration Actions] Set IsConfigurationEditSuccessfullyCompleted",
  props<{ value: boolean }>()
);

export const setIsConfigurationGeAddSuccessfullyCompleted = createAction(
  "[Configuration Actions] Set IsConfigurationGeAddSuccessfullyCompleted",
  props<{ value: boolean }>()
);

export const setIsConfigurationGeEditSuccessfullyCompleted = createAction(
  "[Configuration Actions] Set IsConfigurationGeEditSuccessfullyCompleted",
  props<{ value: boolean }>()
);

export const clearError = createAction(
  "[Configuration Actions] Clear Configurations Error"
);
