import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { LoggerService } from "@app/core/services/logger.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    skipLinkUrl: string = '';

    constructor(private router: Router,
                private matIconRegistry: MatIconRegistry,
                private domSanitizer: DomSanitizer,
                private loggerService: LoggerService,) {
        this.setSkipLinkUrl();
        this.registerMatIcons();
    }

    ngOnInit() {
        // this.loggerService.datadogInitUser(user?.uscId, student?.person?.personName, student?.person?.eaddress);
    }

    private setSkipLinkUrl(): void {
        let path = this.router.url.split('/')[1];

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url != '') {
                    this.skipLinkUrl = event.url;
                } else {
                    this.skipLinkUrl = '';
                }
            }
        })
    }

    private registerMatIcons(): void {
        this.matIconRegistry.addSvgIcon(
            `chevron_down`,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/images/icons/chevron_down.svg`)
        );
    }
}
