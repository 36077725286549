import { Injectable } from "@angular/core";
import { CustomToastrConfig } from "@models/custom-toastr-config.interface";
import { ActiveToast, ToastrService } from "ngx-toastr";


@Injectable()
export class ToastNotificationService {
  // @ts-ignore
  defaults: CustomToastrConfig = {
    timeOut: 5000,
    extendedTimeOut: 5000,
    closeButton: true,
    cancelButton: false
  }

  constructor(private toastr: ToastrService) {
  }

  withCancel(message: string): ActiveToast<any> {
    const config = {
      timeOut: 5000,
      extendedTimeOut: 5000,
      closeButton: true,
      cancelButton: true,
      progressBar: true,
      progressAnimation: "increasing"
    } as CustomToastrConfig;

    return this.fireToastr(message, config);
  }

  informational(message: string): ActiveToast<any> {
    return this.fireToastr(message);
  }

  private fireToastr(message: string, config: CustomToastrConfig = this.defaults): ActiveToast<any> {
    return this.toastr.success(
      message,
      '',
      config,
    )
  }
}
