import { SCHOOLS } from "./school.model";

export class Department implements IDepartment {
  prefix?: string | undefined;
  description?: string | undefined;
  sortIndex?: number;
  schoolPrefix?: string | undefined;

  // clearances?: DepartmentClearance[] | undefined;

  constructor(data?: IDepartment) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.prefix = _data["prefix"];
      this.description = _data["description"];
      this.sortIndex = _data["sortIndex"];
      this.schoolPrefix = _data["schoolPrefix"];
      // if (Array.isArray(_data["clearances"])) {
      //   this.clearances = [] as any;
      //   for (let item of _data["clearances"])
      //     this.clearances!.push(DepartmentClearance.fromJS(item));
      // }
    }
  }

  static fromJS(data: any): Department {
    data = typeof data === 'object' ? data : {};
    let result = new Department();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["prefix"] = this.prefix;
    data["description"] = this.description;
    data["sortIndex"] = this.sortIndex;
    data["schoolPrefix"] = this.schoolPrefix;
    // if (Array.isArray(this.clearances)) {
    //   data["clearances"] = [];
    //   for (let item of this.clearances)
    //     data["clearances"].push(item.toJSON());
    // }
    return data;
  }

  static createFakeDepartment(prefix: string, description: string, schoolPrefix?: string) {
    return new Department({
      prefix: prefix,
      description: description,
      schoolPrefix: schoolPrefix
    });
  }
}

export interface IDepartment {
  prefix?: string | undefined;
  description?: string | undefined;
  sortIndex?: number;
  schoolPrefix?: string | undefined;
  // clearances?: DepartmentClearance[] | undefined;
}

export const DEPARTMENTS: IDepartment[] =
  [
    ...SCHOOLS.filter(s => s.prefix !== "FA")
      .map(s => Department.createFakeDepartment(`${ s.prefix }1`, `DEPT 1`, s.prefix)),

    ...SCHOOLS.filter(s => s.prefix !== "FA")
      .map(s => Department.createFakeDepartment(`${ s.prefix }2`, `DEPT 2`, s.prefix)),

    ...SCHOOLS.filter(s => s.prefix !== "FA")
      .map(s => Department.createFakeDepartment(`${ s.prefix }3`, `DEPT 3`, s.prefix)),

    // new Department({
    //   prefix: "FASC",
    //   description: "Sculpture",
    //   schoolPrefix: "FA"
    // }),
    new Department({
      prefix: "ART",
      description: "Art",
      schoolPrefix: "FA"
    }),
    new Department({
      prefix: "AHIS",
      description: "Art History",
      schoolPrefix: "FA"
    }),
    new Department({
      prefix: "CRIT",
      description: "Critical Studies",
      schoolPrefix: "FA",
    }),
    new Department({
      prefix: "DES",
      description: "Design",
      schoolPrefix: "FA"
    }),
    new Department({
      prefix: "PAS",
      description: "Public Art Studies",
      schoolPrefix: "FA"
    }),
    new Department({
      prefix: "FAD",
      description: "Fine Arts",
      schoolPrefix: "FA"
    }),
    // new Department({
    //   prefix: "FAMS",
    //   description: "Museum Studies",
    //   schoolPrefix: "FA"
    // }),

    // new Department({
    //   prefix: "FAED",
    //   description: "Art Education",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FACE",
    //   description: "Ceramics",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FAIN",
    //   description: "Intermedia",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FAPT",
    //   description: "Painting",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FAPR",
    //   description: "Printmaking",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FADW",
    //   description: "Drawing",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FAPH",
    //   description: "Photography",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FADN",
    //   description: "Design",
    //   schoolPrefix: "FA"
    // }),
    // new Department({
    //   prefix: "FASA",
    //   description: "Studio Arts",
    //   schoolPrefix: "FA"
    // })
  ]
