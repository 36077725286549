import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatChipsModule } from "@angular/material/chips";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { DateInsertAtPipe } from "@app/core/pipes/date-insert-at.pipe";
import { ConfirmationDialogComponent } from "@shared-components/confirmation-dialog/confirmation-dialog.component";
import { CourseStatusComponent } from "@shared-components/course-status/course-status.component";
import { CustomToastComponent } from "@shared-components/custom-toast/custom-toast.component";
import { DClearanceMessageComponent } from "@shared-components/d-clearance-message/d-clearance-message.component";
import {
  AdminNavListItemComponent
} from "@shared-components/nav-list-items/admin-nav-list-item/admin-nav-list-item.component";
import { NavListItemComponent } from "@shared-components/nav-list-items/nav-list-item/nav-list-item.component";
import { SectionStatusComponent } from "@shared-components/section-status/section-status.component";
import { UserComponent } from "@shared-components/user-component/user.component";
import { NgxStyleLibraryModule } from "@uscapp/ngx-style-library";

import { AnnouncementComponent } from './announcement/announcement.component';
import { BadgeComponent } from './badge/badge.component';
import { ChipComponent } from './chip/chip.component';
import { FooterContentComponent } from './footer-content/footer-content.component';
import { HeaderContentComponent } from './header-content/header-content.component';
import { TermDropdownComponent } from './term-dropdown/term-dropdown.component';
import { TermStatusComponent } from './term-status/term-status.component';

@NgModule({
  declarations: [
    TermDropdownComponent,
    FooterContentComponent,
    HeaderContentComponent,
    AnnouncementComponent,
    NavListItemComponent,
    AdminNavListItemComponent,
    DateInsertAtPipe,
    TermStatusComponent,
    DClearanceMessageComponent,
    ConfirmationDialogComponent,
    CustomToastComponent,
    BadgeComponent,
    CourseStatusComponent,
    SectionStatusComponent,
    ChipComponent
  ],
  exports: [
    TermDropdownComponent,
    FooterContentComponent,
    HeaderContentComponent,
    AnnouncementComponent,
    NavListItemComponent,
    AdminNavListItemComponent,
    NgxStyleLibraryModule,
    DateInsertAtPipe,
    TermStatusComponent,
    DClearanceMessageComponent,
    ConfirmationDialogComponent,
    BadgeComponent,
    CourseStatusComponent,
    SectionStatusComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxStyleLibraryModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
  ]
})
export class SharedComponentsModule {
}
