import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { map, Observable } from "rxjs";
import { SchoolVM } from "./WebRegApiClient";


@Injectable()
export class SchoolsService {

  constructor(private apiService: WebRegService) {
  }

  // getAll(): Observable<ISchoolVM[]> {
  //   return this.apiService.peSchoolsAll()
  //     .pipe(map(resp => resp.map(r => r.toJSON() as ISchoolVM)))
  // }

  getByTermCode(termCode: number | null): Observable<SchoolVM[]> {
    return this.apiService.apiSchoolsTermCode(termCode || 0)
      .pipe(map(resp => resp.map(r => r.toJSON() as SchoolVM)));
  }
}
