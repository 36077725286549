import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Optional } from "@angular/core";
import { API_BASE_URL, Client } from "@app/core/services/WebRegApiClient";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class WebRegService extends Client {

  constructor(http: HttpClient,
    private auth: AuthenticationService,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, baseUrl);
  }
}
