import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { map, Observable } from "rxjs";
import { ConfigurationProgram, IConfigurationProgram, IProgramCoursesVM } from "./WebRegApiClient";


@Injectable()
export class ProgramCourseListService {

  constructor(private apiService: WebRegService) {
  }

  getCourses(termCode: number, schoolCode: string, programCode: string): Observable<IProgramCoursesVM> {
    return this.apiService.apiProgramsCourses(termCode, schoolCode, programCode)
      .pipe(map(r => r.toJSON() as IProgramCoursesVM)
      );
  }

  getCoursesByConfig(termCode: number, programConfig: IConfigurationProgram): Observable<IProgramCoursesVM> {
    return this.apiService.apiProgramsCoursesByConfig(termCode, programConfig as ConfigurationProgram)
      .pipe(map(r => r.toJSON() as IProgramCoursesVM)
      );
  }
}
