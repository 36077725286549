import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, shareReplay } from 'rxjs';

const ANONYMOUS: Session = null;
const CACHE_SIZE = 1;

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  private session$: Observable<Session> | null = null
  private jwt$: Observable<string> | null = null


  constructor(private http: HttpClient) {
  }

  public getJwt() {
    this.jwt$ = this.http.get('bff/jwt', {responseType: 'text'} ).pipe(
      catchError(err => {
        return of("err");
      }),
      shareReplay(CACHE_SIZE)
    );

    return this.jwt$;
  }

  public getSession(ignoreCache: boolean = false) {
    if (!this.session$ || ignoreCache) {
      this.session$ = this.http.get<Session>('bff/user').pipe(
        catchError(err => {
          return of(ANONYMOUS);
        }),
        shareReplay(CACHE_SIZE)
      );
    }
    return this.session$;
  }

  public getIsAuthenticated(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(UserIsAuthenticated)
    );
  }

  public getIsAnonymous(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      map(UserIsAnonymous)
    );
  }

  public getUsername(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'name')?.value)
    );
  }

  public getUscId(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'USCID')?.value)
    );
  }

  //todo: Can getIsAdmin and getIsDev be switched to use the role level value.
  public getIsAdmin(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'role')?.value.toLowerCase() === 'admin'
        || s.find(c => c.type === 'role')?.value.toLowerCase() === 'dev')
    );
  }

  public getIsDev(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'role')?.value.toLowerCase() === 'dev')
    );
  }

  public getLogoutUrl(ignoreCache: boolean = false) {
    return this.getSession(ignoreCache).pipe(
      filter(UserIsAuthenticated),
      map(s => s.find(c => c.type === 'auth:logout_url')?.value)
    );
  }
}

export interface Claim {
  type: string;
  value: string;
}

export type Session = Claim[] | null;

function UserIsAuthenticated(s: Session): s is Claim[] {
  return s !== null;
}

function UserIsAnonymous(s: Session): s is null {
  return s === null;
}
