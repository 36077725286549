import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import Swal, { SweetAlertOptions } from 'sweetalert2'

enum AlertTypes {
  Error = 0,
  Success = 1,
  Info = 2,
  Warning = 4,
}

@Injectable()
export class AlertService {
  defaults: SweetAlertOptions = {
    width: 560,
    heightAuto: true,
    padding: 0,
    background: '#F7F7F5',
    showConfirmButton: false,
    position: 'top',
    backdrop: false,
    showCloseButton: true,
    allowEscapeKey: true
  }

  constructor(private sanitizer: DomSanitizer) {
  }

  error(message: string, title: string = 'System Error'): void {
    this.fireAlert(AlertTypes.Error, message, title);
  }

  success(message: string, title: string = 'Success'): void {
    this.fireAlert(AlertTypes.Success, message, title);
  }

  private fireAlert(alertType: AlertTypes, message: string, title: string): void {
    let iconFileName = '';
    let alertTypeClass = '';

    switch (alertType) {
      case AlertTypes.Error:
        iconFileName = 'stop_black.svg';
        alertTypeClass = 'error';
        break;
      case AlertTypes.Success:
        iconFileName = 'success.svg';
        alertTypeClass = 'success';
        break;
      case AlertTypes.Info:
        alertTypeClass = 'info';
        break;
      case AlertTypes.Warning:
        alertTypeClass = 'warning';
        break;
      default:
        break;
    }

    const html = `
            <div class="alert-container" role="alert">
                <div class="icon-container"><img src="assets/images/icons/${ iconFileName }" alt="icon" aria-hidden="true" /></div>
                <div class="contents">
                    <div class="title">${ title }</div>
                    <div class="message">${ message }</div>
                </div>
            </div>
            `

    const sanitizedHtml = this.sanitizer.sanitize(SecurityContext.HTML, html);

    Swal.fire({
      ...this.defaults,
      html: sanitizedHtml ? sanitizedHtml : '',
      customClass: {
        popup: `swal-custom-container ${ alertTypeClass }`,
        closeButton: 'swal-custom-close-button',
      },
    })
  }
}
