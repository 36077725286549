import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { map, Observable } from "rxjs";
import {
  ICoursePrefixVM,
  IGeCodeVm,
  IPeConfigurableSessionVM,
  IPeConfigurableTermVM,
  IPeSession
} from "./WebRegApiClient";


@Injectable()
export class PeService {

  constructor(private apiService: WebRegService) {
  }

  getConfigurableTermsFromPe(): Observable<IPeConfigurableTermVM[]> {
    return this.apiService.apiPeGetConfigurableTermsFromPe()
      .pipe(map(resp => resp.map(r => r.toJSON() as IPeConfigurableTermVM)));
  }

  getConfigurableSessions(termCode: number): Observable<IPeConfigurableSessionVM[]> {
    return this.apiService.apiPeGetConfigurableSessionsByTermCode(termCode)
      .pipe(map(resp =>
        resp.map(r => r.toJSON() as IPeConfigurableSessionVM)))
  }

  getSessions(): Observable<IPeSession[]> {
    return this.apiService.apiPeSessionsAll()
      .pipe(map(resp => resp.map(r => r.toJSON() as IPeSession)));
  }

  getSessionByRnrSessionId(rnrSessionId: number): Observable<IPeSession> {
    return this.apiService.apiPeSessionByRnrSessionId(rnrSessionId)
      .pipe(map(resp => resp.toJSON() as IPeSession))
  }

  getCoursePrefixes(): Observable<ICoursePrefixVM[]> {
    return this.apiService.apiPeCoursePrefixesAll()
      .pipe(map(resp => resp.map(r => r.toJSON() as ICoursePrefixVM)));
  }

  getGeCodes(): Observable<IGeCodeVm[]> {
    return this.apiService.apiPeGeCodes()
      .pipe(map(resp => resp.map(r => r.toJSON() as IGeCodeVm)));
  }
}
