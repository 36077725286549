import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebRegService } from "@app/core/services/api-request.service";
import { ITermAddVM, ITermVM, TermAddVM, TermVM } from "@app/core/services/WebRegApiClient";
import { map, Observable } from "rxjs";


@Injectable()
export class TermsService {

  constructor(private apiService: WebRegService, private http: HttpClient) {
  }

  getActive(): Observable<ITermVM[]> {
    return this.apiService.apiTermsActive()
      .pipe(map(resp => {
        return resp.map(r => r.toJSON() as ITermVM)
      }));
  }

  getAll(): Observable<ITermVM[]> {
    return this.apiService.apiTermsAll()
      .pipe(map(resp => resp.map(r => r.toJSON() as ITermVM)));
  }

  loadSelectedTerm(termCode: number | null): Observable<ITermVM> {
    return this.apiService.apiTermsTermCode(termCode || 0)
      .pipe(map((r => r.toJSON() as ITermVM)));
  }

  getByTermCode(termCode: number | null): Observable<ITermVM> {
    return this.apiService.apiTermsTermCode(termCode || 0)
      .pipe(map((r => r.toJSON() as ITermVM)));
  }

  add(term: ITermAddVM): Observable<ITermVM> {
    let termToCreate = new TermAddVM();
    termToCreate.season = term.season;
    termToCreate.year = term.year;
    termToCreate.copiedFromTermCode = term.copiedFromTermCode;
    termToCreate.dateActive = term.dateActive;
    termToCreate.dateArchived = term.dateArchived;

    return this.apiService.apiTermsNew(termToCreate)
      .pipe(map(r => r as ITermVM));
  }

  delete(termCode: number): Observable<string> {
    return this.apiService.apiTermsDelete(termCode)
      .pipe(map(resp => resp as string));
  }

  update(term: ITermVM): Observable<TermVM> {
    let termToUpdate = new TermVM();
    termToUpdate.termCode = term.termCode;
    termToUpdate.dateActive = term.dateActive;
    termToUpdate.season = term.season;
    termToUpdate.year = term.year;

    return this.apiService.apiTermsEdit(termToUpdate)
      .pipe(map(resp => resp as TermVM));
  }
}
