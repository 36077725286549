import { coursePrefixesReducer } from '@app/store/coursePrefixes/coursePrefixes.reducer';
import { coursesReducer } from '@app/store/courses/courses.reducer';
import { programsReducer } from "@app/store/programs/programs.reducer";
import { schoolsReducer } from "@app/store/schools/schools.reducer";
import { peConfigurableSessionsReducer } from '@app/store/pe-configurable-sessions/pe-configurable-sessions.reducer';
import { configurationsReducer } from '@modules/admin/semesters/configuration/store/configurations.reducer';
import { appTitleReducer } from "@modules/main/homepage/store/app-title/app-title.reducer";
import { termsReducer } from "@modules/main/homepage/store/terms/terms.reducer";
import { routerReducer } from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { programDetailsReducer } from "@app/store/program-details/program-details.reducer";
import { sessionsReducer } from "@app/store/sessions/sessions.reducer";
import { programCourseListReducer } from "@app/store/program-course-list/program-course-list.reducer";

export interface AppState {
}

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  terms: termsReducer,
  appTitle: appTitleReducer,
  schools: schoolsReducer,
  programs: programsReducer,
  configurations: configurationsReducer,
  peConfigurableSessions: peConfigurableSessionsReducer,
  coursePrefixes: coursePrefixesReducer,
  programDetails: programDetailsReducer,
  sessions: sessionsReducer,
  programCourseList: programCourseListReducer,
  courses: coursesReducer
};

export function logger(reducer: ActionReducer<any>)
  : ActionReducer<any> {
  return (state, action) => {
    console.log('state before: ', state);
    console.log("action", action);

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = [];
