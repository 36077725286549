import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { getMinutesFromTime24, getTime24FromMinutes } from '@app/core/services/dateTime.service';

export interface IFilterOptions {
  days: string[];
  timeRangeMin: string;
  timeRangeMax: string;
  units: number;
  isAvailableCoursesChecked: boolean;
  isOnlineCoursesChecked: boolean;
  isOpenToAllChecked: boolean;
}

export const FilterDefault: IFilterOptions = {
  days: ['Any'],
  timeRangeMin: '05:00',
  timeRangeMax: '23:30',
  units: 0,
  isAvailableCoursesChecked: false,
  isOnlineCoursesChecked: false,
  isOpenToAllChecked: false
}

@Component({
  selector: 'filter-dialog',
  styleUrls: ['filter-dialog.component.scss'],
  templateUrl: 'filter-dialog.component.html',
})
export class FilterDialog {
  timeRangeStep: number = 30;

  timeMin: string = "05:00";
  timeMax: string = "23:30";
  timeRangeMin: number = getMinutesFromTime24(this.timeMin);
  timeRangeMax: number = getMinutesFromTime24(this.timeMax);

  selectedMinTime: string = this.timeMin;
  selectedMaxTime: string = this.timeMax;

  monControl = new FormControl(false);
  tueControl = new FormControl(false);
  wedControl = new FormControl(false);
  thuControl = new FormControl(false);
  friControl = new FormControl(false);
  satControl = new FormControl(false);
  sunControl = new FormControl(false);

  minTimeControl = new FormControl(this.timeRangeMin);
  maxTimeControl = new FormControl(this.timeRangeMax);

  unitsControl = new FormControl({ value: '1', disabled: false });

  availableCoursesControl = new FormControl({ value: false, disabled: false });
  onlineCoursesControl = new FormControl({ value: false, disabled: false });
  allMajorsControl = new FormControl({ value: false, disabled: false });

  constructor(
    public dialogRef: MatDialogRef<FilterDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IFilterOptions,
  ) {
    if (!data) {
      data = FilterDefault
    }

    this.monControl.setValue(data.days.includes('Mon') || false);
    this.tueControl.setValue(data.days.includes('Tue') || false);
    this.wedControl.setValue(data.days.includes('Wed') || false);
    this.thuControl.setValue(data.days.includes('Thu') || false);
    this.friControl.setValue(data.days.includes('Fri') || false);
    this.satControl.setValue(data.days.includes('Sat') || false);
    this.sunControl.setValue(data.days.includes('Sun') || false);
    this.unitsControl.setValue(data.units.toString() || '0');
    this.availableCoursesControl.setValue(data.isAvailableCoursesChecked);
    this.onlineCoursesControl.setValue(data.isOnlineCoursesChecked);
    this.allMajorsControl.setValue(data.isOpenToAllChecked);

    console.log("Filter Dialog Contstructor");
    console.log(data.timeRangeMin);
    console.log(data.timeRangeMax);

    const minTime: string = data.timeRangeMin
      ? data.timeRangeMin
      : this.timeMin;
    const maxTime: string = data.timeRangeMax
      ? data.timeRangeMax
      : this.timeMax;

    this.minTimeControl.setValue(getMinutesFromTime24(minTime));
    this.maxTimeControl.setValue(getMinutesFromTime24(maxTime));

    this.selectedMinTime = getTime24FromMinutes(getMinutesFromTime24(minTime) || this.timeRangeMin);
    this.selectedMaxTime = getTime24FromMinutes(getMinutesFromTime24(maxTime) || this.timeRangeMax);

    this.minTimeControl.valueChanges.pipe().subscribe(value => {
      this.selectedMinTime = getTime24FromMinutes(value || this.timeRangeMin);
    });

    this.maxTimeControl.valueChanges.pipe().subscribe(value => {
      this.selectedMaxTime = getTime24FromMinutes(value || this.timeRangeMax);
    });
  }

  onApplyClick() {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const dayBools: boolean[] = [
      this.monControl.value || false,
      this.tueControl.value || false,
      this.wedControl.value || false,
      this.thuControl.value || false,
      this.friControl.value || false,
      this.satControl.value || false,
      this.sunControl.value || false,
    ];

    const reqDays: string[] = dayBools.map((s, i) => s ? days[i] : null)
      .filter(d => d !== null)
      .map(i => String(i));

    const filtersToApply: IFilterOptions = {
      days: reqDays.length === 0 ? ['Any'] : reqDays,
      timeRangeMin: getTime24FromMinutes(this.minTimeControl.value || this.timeRangeMin),
      timeRangeMax: getTime24FromMinutes(this.maxTimeControl.value || this.timeRangeMax),
      units: parseInt(this.unitsControl.value || "0"),
      isAvailableCoursesChecked: this.availableCoursesControl.value || false,
      isOnlineCoursesChecked: this.onlineCoursesControl.value || false,
      isOpenToAllChecked: this.onlineCoursesControl.value || false,
    }

    this.dialogRef.close(filtersToApply);
  }

  onResetFilters() {
    this.monControl.setValue(FilterDefault.days.includes('Mon') || false);
    this.tueControl.setValue(FilterDefault.days.includes('Tue') || false);
    this.wedControl.setValue(FilterDefault.days.includes('Wed') || false);
    this.thuControl.setValue(FilterDefault.days.includes('Thu') || false);
    this.friControl.setValue(FilterDefault.days.includes('Fri') || false);
    this.satControl.setValue(FilterDefault.days.includes('Sat') || false);
    this.sunControl.setValue(FilterDefault.days.includes('Sun') || false);
    this.unitsControl.setValue(FilterDefault.units.toString());
    this.availableCoursesControl.setValue(FilterDefault.isAvailableCoursesChecked);
    this.onlineCoursesControl.setValue(FilterDefault.isOnlineCoursesChecked);
    this.allMajorsControl.setValue(FilterDefault.isOpenToAllChecked);
    this.minTimeControl.setValue(getMinutesFromTime24(FilterDefault.timeRangeMin));
    this.maxTimeControl.setValue(getMinutesFromTime24(FilterDefault.timeRangeMax));
  }

  onNoClick(): void {
    this.dialogRef.close(undefined);
  }
}