import { ClearanceModel } from "@app/core/mocks/clearance.model";
import { CourseModel } from "@app/core/mocks/course.model";
import { ModeModel } from "@app/core/mocks/mode.model";
import { SectionInstructor, SectionInstructors } from "@app/core/mocks/section-instructor.model";
import { SECTION_LOCATIONS, SectionLocation } from "@app/core/mocks/section-location.model";
import { WaitlistModel } from "@app/core/mocks/waitlist.model";
import { ACTIVE_TERMS } from "./active-terms.constant";
import { ARCHIVED_TERMS } from "./archived-terms.constant";
import { SessionModel, SESSIONS } from "./session.model";

export class SectionModel implements ISectionModel {
  sectionId?: number;
  courseId?: number;
  type?: string | undefined;
  sisSectionId?: string | undefined;
  termCode?: string | undefined;
  rnrSessionId?: number;
  name?: string | undefined;
  notes?: string | undefined;
  description?: string | undefined;
  departmentalClearanceFlag?: string | undefined;
  rnrModeCode?: string | undefined;
  linkCode?: string | undefined;
  campusCode?: string | undefined;
  seats?: number;
  waitlistSize?: number;
  roomId?: number;
  cancelDate?: Date | undefined;
  manualDate?: Date | undefined;
  publishFlag?: string | undefined;
  publishSectionFlag?: string | undefined;
  blackboardFlag?: string | undefined;
  dlCode?: string | undefined;
  numberRegistered?: number;
  spacesAvailable?: number;
  isFull?: boolean;
  isClosed?: boolean;
  isCanceled?: boolean;
  session?: SessionModel;
  course?: CourseModel;
  rnrMode?: ModeModel;
  sectionUnits?: number | undefined;
  linkedSections?: number[] | undefined;
  locations?: SectionLocation[] | undefined;
  instructors?: SectionInstructor[] | undefined;
  waitlists?: WaitlistModel[] | undefined;
  clearances?: ClearanceModel[] | undefined;

  constructor(data?: ISectionModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.sectionId = _data["sectionId"];
      this.courseId = _data["courseId"];
      this.type = _data["type"];
      this.sisSectionId = _data["sisSectionId"];
      this.termCode = _data["termCode"];
      this.rnrSessionId = _data["rnrSessionId"];
      this.name = _data["name"];
      this.notes = _data["notes"];
      this.description = _data["description"];
      this.departmentalClearanceFlag = _data["departmentalClearanceFlag"];
      this.rnrModeCode = _data["rnrModeCode"];
      this.linkCode = _data["linkCode"];
      this.campusCode = _data["campusCode"];
      this.seats = _data["seats"];
      this.waitlistSize = _data["waitlistSize"];
      this.roomId = _data["roomId"];
      this.cancelDate = _data["cancelDate"] ? new Date(_data["cancelDate"].toString()) : <any>undefined;
      this.manualDate = _data["manualDate"] ? new Date(_data["manualDate"].toString()) : <any>undefined;
      this.publishFlag = _data["publishFlag"];
      this.publishSectionFlag = _data["publishSectionFlag"];
      this.blackboardFlag = _data["blackboardFlag"];
      this.dlCode = _data["dlCode"];
      this.numberRegistered = _data["numberRegistered"];
      this.spacesAvailable = _data["spacesAvailable"];
      this.isFull = _data["isFull"];
      this.isClosed = _data["isClosed"];
      this.isCanceled = _data["isCanceled"];
      this.sectionUnits = _data["sectionUnits"];
      this.session = _data["session"] ? SessionModel.fromJS(_data["session"]) : <any>undefined;
      this.course = _data["course"] ? CourseModel.fromJS(_data["course"]) : <any>undefined;
      this.rnrMode = _data["rnrMode"] ? ModeModel.fromJS(_data["rnrMode"]) : <any>undefined;
      // if (Array.isArray(_data["sectionUnits"])) {
      //   this.sectionUnits = [] as any;
      //   for (let item of _data["sectionUnits"])
      //     this.sectionUnits!.push(SectionUnitModel.fromJS(item));
      // }
      if (Array.isArray(_data["linkedSections"])) {
        this.linkedSections = [] as any;
        for (let item of _data["linkedSections"])
          this.linkedSections!.push(item);
      }
      if (Array.isArray(_data["locations"])) {
        this.locations = [] as any;
        for (let item of _data["locations"])
          this.locations!.push(SectionLocation.fromJS(item));
      }
      if (Array.isArray(_data["instructors"])) {
        this.instructors = [] as any;
        for (let item of _data["instructors"])
          this.instructors!.push(SectionInstructor.fromJS(item));
      }
      if (Array.isArray(_data["waitlists"])) {
        this.waitlists = [] as any;
        for (let item of _data["waitlists"])
          this.waitlists!.push(WaitlistModel.fromJS(item));
      }
      if (Array.isArray(_data["clearances"])) {
        this.clearances = [] as any;
        for (let item of _data["clearances"])
          this.clearances!.push(ClearanceModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): SectionModel {
    data = typeof data === 'object' ? data : {};
    let result = new SectionModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["sectionId"] = this.sectionId;
    data["courseId"] = this.courseId;
    data["type"] = this.type;
    data["sisSectionId"] = this.sisSectionId;
    data["termCode"] = this.termCode;
    data["rnrSessionId"] = this.rnrSessionId;
    data["name"] = this.name;
    data["notes"] = this.notes;
    data["description"] = this.description;
    data["departmentalClearanceFlag"] = this.departmentalClearanceFlag;
    data["rnrModeCode"] = this.rnrModeCode;
    data["linkCode"] = this.linkCode;
    data["campusCode"] = this.campusCode;
    data["seats"] = this.seats;
    data["waitlistSize"] = this.waitlistSize;
    data["roomId"] = this.roomId;
    data["cancelDate"] = this.cancelDate ? this.cancelDate.toISOString() : <any>undefined;
    data["manualDate"] = this.manualDate ? this.manualDate.toISOString() : <any>undefined;
    data["publishFlag"] = this.publishFlag;
    data["publishSectionFlag"] = this.publishSectionFlag;
    data["blackboardFlag"] = this.blackboardFlag;
    data["dlCode"] = this.dlCode;
    data["numberRegistered"] = this.numberRegistered;
    data["spacesAvailable"] = this.spacesAvailable;
    data["isFull"] = this.isFull;
    data["isClosed"] = this.isClosed;
    data["sectionUnits"] = this.sectionUnits;
    data["isCanceled"] = this.isCanceled;
    data["session"] = this.session ? this.session.toJSON() : <any>undefined;
    data["course"] = this.course ? this.course.toJSON() : <any>undefined;
    data["rnrMode"] = this.rnrMode ? this.rnrMode.toJSON() : <any>undefined;
    // if (Array.isArray(this.sectionUnits)) {
    //   data["sectionUnits"] = [];
    //   for (let item of this.sectionUnits)
    //     data["sectionUnits"].push(item.toJSON());
    // }
    if (Array.isArray(this.linkedSections)) {
      data["linkedSections"] = [];
      for (let item of this.linkedSections)
        data["linkedSections"].push(item);
    }
    if (Array.isArray(this.locations)) {
      data["locations"] = [];
      for (let item of this.locations)
        data["locations"].push(item.toJSON());
    }
    if (Array.isArray(this.instructors)) {
      data["instructors"] = [];
      for (let item of this.instructors)
        data["instructors"].push(item.toJSON());
    }
    if (Array.isArray(this.waitlists)) {
      data["waitlists"] = [];
      for (let item of this.waitlists)
        data["waitlists"].push(item.toJSON());
    }
    if (Array.isArray(this.clearances)) {
      data["clearances"] = [];
      for (let item of this.clearances)
        data["clearances"].push(item.toJSON());
    }
    return data;
  }

  static CreateFakeSection(termCode: number, index: number): SectionModel {
    const stringId: string = `${termCode}${index}`;
    const intId: number = parseInt(stringId);

    return new SectionModel({
      sectionId: intId,
      courseId: intId,
      type: "Lecture",
      sisSectionId: "SIS" + stringId,
      termCode: stringId,
      rnrSessionId: intId,
      name: stringId + " Name",
      notes: stringId + " Notes",
      description: stringId + " Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 17,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: undefined,
      spacesAvailable: undefined,
      sectionUnits: 1,
      instructors: SectionInstructors.filter(i => i.sectionId === intId).map(i => new SectionInstructor(i)),
      session: new SessionModel(SESSIONS.find(s => s.rnrSessionId === intId)),
    })
  }
}

export interface ISectionModel {
  sectionId?: number;
  courseId?: number;
  type?: string | undefined;
  sisSectionId?: string | undefined;
  termCode?: string | undefined;
  rnrSessionId?: number;
  name?: string | undefined;
  notes?: string | undefined;
  description?: string | undefined;
  departmentalClearanceFlag?: string | undefined;
  rnrModeCode?: string | undefined;
  linkCode?: string | undefined;
  campusCode?: string | undefined;
  seats?: number;
  waitlistSize?: number;
  roomId?: number;
  cancelDate?: Date | undefined;
  manualDate?: Date | undefined;
  publishFlag?: string | undefined;
  publishSectionFlag?: string | undefined;
  blackboardFlag?: string | undefined;
  dlCode?: string | undefined;
  numberRegistered?: number;
  spacesAvailable?: number;
  isFull?: boolean;
  isClosed?: boolean;
  isCanceled?: boolean;
  session?: SessionModel;
  course?: CourseModel;
  rnrMode?: ModeModel;
  sectionUnits?: number | undefined;
  linkedSections?: number[] | undefined;
  locations?: SectionLocation[] | undefined;
  instructors?: SectionInstructor[] | undefined;
  waitlists?: WaitlistModel[] | undefined;
  clearances?: ClearanceModel[] | undefined;
}

export const SECTIONS: ISectionModel[] = [
  ...[
    new SectionModel({
      sectionId: 33001,
      courseId: 23301,
      type: "Lecture",
      sisSectionId: "33001",
      termCode: "20233",
      rnrSessionId: 33001,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33001).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33001).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33021,
      courseId: 23321,
      type: "Lecture",
      sisSectionId: "33001",
      termCode: "20233",
      rnrSessionId: 33001,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33021).map(l => new SectionLocation(l)),
      instructors: undefined, //SectionInstructors.filter(i => i.sectionId === 33001).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33031,
      courseId: 23331,
      type: "Lecture",
      sisSectionId: "33001",
      termCode: "20233",
      rnrSessionId: 33001,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33031).map(l => new SectionLocation(l)),
      instructors: undefined, //SectionInstructors.filter(i => i.sectionId === 33001).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33041,
      courseId: 23341,
      type: "Lecture",
      sisSectionId: "33001",
      termCode: "20233",
      rnrSessionId: 33001,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33041).map(l => new SectionLocation(l)),
      instructors: undefined, //SectionInstructors.filter(i => i.sectionId === 33001).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33002,
      courseId: 23302,
      type: "Lecture",
      sisSectionId: "33002",
      termCode: "20233",
      rnrSessionId: 33002,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 120,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 105,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33002).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33002).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33003,
      courseId: 23303,
      type: "Lecture",
      sisSectionId: "33003",
      termCode: "20233",
      rnrSessionId: 33003,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 75,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 19,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33003).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33003).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33004,
      courseId: 23303,
      type: "Lecture",
      sisSectionId: "33004",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 75,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 19,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33004).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33004).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33005,
      courseId: 23303,
      type: "Lecture",
      sisSectionId: "33005",
      termCode: "20233",
      rnrSessionId: 33005,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 75,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 19,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33005).map(l => new SectionLocation(l)),
      instructors: undefined //SectionInstructors.filter(i => i.sectionId === 33005).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 11805,
      courseId: 23300,
      type: "Lecture",
      sisSectionId: "33434",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 150,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 130,
      spacesAvailable: 20,
      sectionUnits: 4,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 11805).map(l => new SectionLocation(l)),
      instructors: undefined,
    }),
    new SectionModel({
      sectionId: 33004,
      courseId: 23304,
      type: "Lecture",
      sisSectionId: "33434",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 75,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 19,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33004).map(l => new SectionLocation(l)),
      instructors: undefined//SectionInstructors.filter(i => i.sectionId === 33004).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 55411,
      courseId: 25689,
      type: "Lecture",
      sisSectionId: "33434",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 16,
      spacesAvailable: undefined,
      sectionUnits: 2,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 55411).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 55411).map(i => new SectionInstructor(i)),
    }),

    //AHIS 500 Start
    new SectionModel({
      sectionId: 33432,
      courseId: 12543,
      type: "Lecture",
      sisSectionId: "33434",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33432).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33432).map(i => new SectionInstructor(i)),
    }),    
    new SectionModel({
      sectionId: 33433,
      courseId: 12543,
      type: "Lecture",
      sisSectionId: "33434",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 10,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33433).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33433).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33434,
      courseId: 12543,
      type: "Lecture",
      sisSectionId: "33434",
      termCode: "20233",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 16,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33434).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33434).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33435,
      courseId: 12543,
      type: "Lecture",
      sisSectionId: "33435",
      termCode: "20233",
      rnrSessionId: 33435,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 18,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33435).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33435).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33436,
      courseId: 12543,
      type: "Lecture",
      sisSectionId: "33436",
      termCode: "20233",
      rnrSessionId: 33436,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 8,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33436).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 33436).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 33437,
      courseId: 12543,
      type: "Lecture",
      sisSectionId: "33437",
      termCode: "20233",
      rnrSessionId: 33437,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 3,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 33437).map(l => new SectionLocation(l)),
      instructors: undefined//SectionInstructors.filter(i => i.sectionId === 33437).map(i => new SectionInstructor(i)),
    }),


    //***
    new SectionModel({
      sectionId: 10011,
      courseId: 1001,
      type: "Discussion",
      sisSectionId: "1001",
      termCode: "20233",
      rnrSessionId: 1001,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 16,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 10011).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 10011).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 10012,
      courseId: 1001,
      type: "Discussion",
      sisSectionId: "1001",
      termCode: "20233",
      rnrSessionId: 1001,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 16,
      spacesAvailable: undefined,
      sectionUnits: 4,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 10012).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 10012).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 10022,
      courseId: 1002,
      type: "Discussion",
      sisSectionId: "10022",
      termCode: "20233",
      rnrSessionId: 10022,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 16,
      spacesAvailable: undefined,
      sectionUnits: 4,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 10022).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 10022).map(i => new SectionInstructor(i)),
    }),

    //2022
    new SectionModel({
      sectionId: 23001,
      courseId: 22301,
      type: "Lecture",
      sisSectionId: "23001",
      termCode: "20223",
      rnrSessionId: 23001,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 23001).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 23001).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 23002,
      courseId: 22302,
      type: "Lecture",
      sisSectionId: "23002",
      termCode: "20223",
      rnrSessionId: 23002,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 120,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 105,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 23002).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 23002).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 23003,
      courseId: 22303,
      type: "Lecture",
      sisSectionId: "23003",
      termCode: "20223",
      rnrSessionId: 23003,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 75,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 19,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 23003).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 23003).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 11808,
      courseId: 22300,
      type: "Lecture",
      sisSectionId: "33432",
      termCode: "20223",
      rnrSessionId: 33434,
      name: "",
      notes: "",
      description: "",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 150,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 130,
      spacesAvailable: 20,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 11808).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 11808).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 23004,
      courseId: 22304,
      type: "Lecture",
      sisSectionId: "23004",
      termCode: "20233",
      rnrSessionId: 23004,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 75,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 19,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 23004).map(l => new SectionLocation(l)),
      instructors: undefined//SectionInstructors.filter(i => i.sectionId === 33004).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 22411,
      courseId: 22689,
      type: "Lecture",
      sisSectionId: "22411",
      termCode: "20223",
      rnrSessionId: 22411,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 16,
      spacesAvailable: undefined,
      sectionUnits: 2,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 22411).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 22411).map(i => new SectionInstructor(i)),
    }),
    new SectionModel({
      sectionId: 23432,
      courseId: 11543,
      type: "Lecture",
      sisSectionId: "23432",
      termCode: "20223",
      rnrSessionId: 23432,
      name: "",
      notes: "",
      description: "Description",
      departmentalClearanceFlag: "Y",
      rnrModeCode: "C",
      linkCode: undefined,
      campusCode: "UPC-1",
      seats: 20,
      waitlistSize: 0,
      roomId: 1,
      cancelDate: undefined,
      manualDate: undefined,
      publishFlag: "Y",
      publishSectionFlag: "Y",
      blackboardFlag: "N",
      dlCode: undefined,
      numberRegistered: 5,
      spacesAvailable: undefined,
      sectionUnits: 1,
      locations: SECTION_LOCATIONS.filter(l => l.sectionId === 23432).map(l => new SectionLocation(l)),
      instructors: SectionInstructors.filter(i => i.sectionId === 23432).map(i => new SectionInstructor(i)),
    }),



  ],

  ...ACTIVE_TERMS.map((t) => t.termCode)
    .map((termCode) => {
      const actives = [];
      for (let courseCount = 0; courseCount < 13; courseCount++) {
        for (let sectionCount = 0; sectionCount < 2; sectionCount++) {
          actives.push(new SectionModel({
            sectionId: parseInt(`${termCode}${courseCount}${sectionCount}`),
            courseId: parseInt(`${termCode}${courseCount}`),
            type: "Lecture",
            sisSectionId: `${termCode}${courseCount}${sectionCount}`,
            termCode: `${termCode}`,
            rnrSessionId: parseInt(`${termCode}${courseCount}${sectionCount}`),
            name: `Section ${termCode}${courseCount}${sectionCount} Name`,
            notes: "Notes",
            description: "Description",
            departmentalClearanceFlag: "Y",
            rnrModeCode: "C",
            linkCode: undefined,
            campusCode: "UPC-1",
            seats: 17,
            waitlistSize: 0,
            roomId: 1,
            cancelDate: undefined,
            manualDate: undefined,
            publishFlag: "Y",
            publishSectionFlag: "Y",
            blackboardFlag: "N",
            dlCode: undefined,
            numberRegistered: undefined,
            spacesAvailable: undefined,
            sectionUnits: 1,
            locations: SECTION_LOCATIONS.filter(l => l.sectionId === parseInt(`${termCode}${courseCount}${sectionCount}`)).map(l => new SectionLocation(l)),
            instructors: SectionInstructors.filter(i => i.sectionId === parseInt(`${termCode}${courseCount}${sectionCount}`)).map(i => new SectionInstructor(i)),
            session: new SessionModel(SESSIONS.find(s => s.rnrSessionId === parseInt(`${termCode}`))),
          })
          )
        }
      }
      return actives
    }
    ).flat(),
  ...ARCHIVED_TERMS.map((t) => t.termCode)
    .map((termCode) => {
      const actives = [];
      for (let courseCount = 0; courseCount < 13; courseCount++) {
        for (let sectionCount = 0; sectionCount < 2; sectionCount++) {
          actives.push(new SectionModel({
            sectionId: parseInt(`${termCode}${courseCount}${sectionCount}`),
            courseId: parseInt(`${termCode}${courseCount}`),
            type: "Lecture",
            sisSectionId: `${termCode}${courseCount}${sectionCount}`,
            termCode: `${termCode}`,
            rnrSessionId: parseInt(`${termCode}${courseCount}${sectionCount}`),
            name: `Section ${termCode}${courseCount}${sectionCount} Name`,
            notes: "Notes",
            description: "Description",
            departmentalClearanceFlag: "Y",
            rnrModeCode: "C",
            linkCode: undefined,
            campusCode: "UPC-1",
            seats: 17,
            waitlistSize: 0,
            roomId: 1,
            cancelDate: undefined,
            manualDate: undefined,
            publishFlag: "Y",
            publishSectionFlag: "Y",
            blackboardFlag: "N",
            dlCode: undefined,
            numberRegistered: undefined,
            spacesAvailable: undefined,
            sectionUnits: 4,
            locations: SECTION_LOCATIONS.filter(l => l.sectionId === parseInt(`${termCode}${courseCount}${sectionCount}`)).map(l => new SectionLocation(l)),
            instructors: SectionInstructors.filter(i => i.sectionId === parseInt(`${termCode}${courseCount}${sectionCount}`)).map(i => new SectionInstructor(i)),
            session: new SessionModel(SESSIONS.find(s => s.rnrSessionId === parseInt(`${termCode}`))),
          })
          )
        }
      }
      return actives
    }
    ).flat()
];
