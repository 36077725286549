import { IProgramCoursesVM } from "@app/core/services/WebRegApiClient";
import { ProgramCourseListActions } from "@app/store/program-course-list/program-course-list-action-types";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface ProgramCourseListState extends EntityState<IProgramCoursesVM> {
  areProgramCourseListLoadedForTermSchoolProgram: Record<string, boolean>;
  error: ApiResponseErrorModel | string | undefined;
}

export function selectId(a: IProgramCoursesVM): string {
  return <number>a.termCode + <string>a.schoolPrefix + <string>a.programPrefix;
}

export const areProgramCourseListLoadedForTermSchoolProgram = (state: ProgramCourseListState, concatenatedKey: string) => state.areProgramCourseListLoadedForTermSchoolProgram[concatenatedKey];

export const adapter = createEntityAdapter<IProgramCoursesVM>(
  {
    selectId: selectId
  }
);

export const initialProgramCourseListState = adapter.getInitialState({
  areProgramCourseListLoadedForTermSchoolProgram: {}
});

export const getErrorMessage = (state: ProgramCourseListState) => state.error;

export const programCourseListReducer = createReducer(
  initialProgramCourseListState,

  on(ProgramCourseListActions.loadProgramCourseListSuccess, (state, action) =>
    adapter.addOne(action.programCourseList, {
      ...state, areProgramCourseListLoadedForTermSchoolProgram: {
        ...state.areProgramCourseListLoadedForTermSchoolProgram,
        [action.concatenatedKey]: true
      }
    })
  ),

  on(ProgramCourseListActions.loadProgramCourseListFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(ProgramCourseListActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
