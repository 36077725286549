import { ISchoolVM } from "@app/core/services/WebRegApiClient";
import * as fromSchools from "@app/store/schools/schools.reducer";
import { SchoolsState } from "@app/store/schools/schools.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

export const selectSchoolsState = createFeatureSelector<SchoolsState>("schools");

export const areSchoolsLoadedForTerm = (props: { termCode: number }) => createSelector(
    selectSchoolsState,
    (state) => {
        return fromSchools.areSchoolsLoadedForTerm(state, props.termCode)
    }
);

export const selectSchoolsError = createSelector(
    selectSchoolsState,
    fromSchools.getErrorMessage
);

export const selectAllSchools = createSelector(
    selectSchoolsState,
    fromSchools.selectAll
);

export const selectAllSchoolsSorted = createSelector(
    selectAllSchools,
    (schools) => schools.sort((a, b) => {
        var bVal: number = 0;
        if (b !== undefined) {
            bVal = b.termCode as number;

        }

        var aVal: number = 0;
        if (a !== undefined) {
            aVal = a.termCode as number;

        }

        return bVal - aVal;
    })
);

export const selectAllSchoolsEntities = createSelector(
    selectSchoolsState,
    fromSchools.selectEntities
)

export const selectSchoolById = (props: { id: number }) => createSelector(
    selectAllSchoolsEntities,
    schools => schools[props.id]
);

export const selectSchoolsByTermCode = (props: { termCode: number }) => createSelector(
    selectAllSchools,
    (schools) => {
        return schools.filter((school: ISchoolVM) => school.termCode == props.termCode)
    }
);

export const selectSchoolEntityIdsByTermCode = (props: { termCode: number }) => createSelector(
    selectAllSchoolsEntities,
    (entities) => Object.keys(entities).filter((id: string | number) => entities[id]?.termCode == props.termCode) as string[]
);

