import { ISchoolVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadSchoolsByTermCode = createAction(
  "[School Actions] Load Schools By Term Code",
  props<{ termCode: number }>()
);

export const loadSchoolsByTermCodeSuccess = createAction(
  "[School Actions] Schools Successfully Loaded",
  props<{ schools: ISchoolVM[], termCode: number }>()
);

export const loadSchoolsByTermCodeFailure = createAction(
  "[School Effect] Load Schools By Term Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);

export const removeSchoolsByTermCode = createAction(
  "[School Actions] Remove Schools By Term Code",
  props<{ schools: string[], termCode: number }>()
);

export const clearError = createAction(
  "[School Actions] Clear Schools Error"
);
