import { ITermVM, TermSeason, TermStatus, TermVM } from "@app/core/services/WebRegApiClient";

export const ARCHIVED_TERMS: ITermVM[] = [
  new TermVM(
    {
      termCode: 20223,
      season: TermSeason.Fall,
      year: 2022,
      seasonName: 'Fall',
      statusCodes: [TermStatus.Archived]
    }
  ),
  new TermVM(
    {
      termCode: 20222,
      season: TermSeason.Summer,
      year: 2022,
      seasonName: 'Summer',
      statusCodes: [TermStatus.Archived]
    }
  ),
  new TermVM(
    {
      termCode: 20221,
      season: TermSeason.Spring,
      year: 2022,
      seasonName: 'Spring',
      statusCodes: [TermStatus.Archived]
    }
  ),  
  new TermVM(
    {
      termCode: 20213,
      season: TermSeason.Fall,
      year: 2021,
      seasonName: 'Fall',
      statusCodes: [TermStatus.Archived]
    }
  ),
  new TermVM(
    {
      termCode: 20212,
      season: TermSeason.Summer,
      year: 2021,
      seasonName: 'Summer',
      statusCodes: [TermStatus.Archived]
    }
  ),
  new TermVM(
    {
      termCode: 20211,
      season: TermSeason.Spring,
      year: 2021,
      seasonName: 'Spring',
      statusCodes: [TermStatus.Archived]
    }
  ),
]