import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable, of} from "rxjs";
import {AlertService} from "@app/core/services/alert.service";

// @Injectable({
//   providedIn: 'root'
// })
// export class CanDeactivateAdminFormGuard implements CanActivate {
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//
//     return true;
//   }
// }
//
export class Permissions {
  canDeactivate(id: string): boolean {
    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateAdminFormGuard  {
  constructor(private permissions: Permissions, private alertService: AlertService) {
  }

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const result = this.permissions.canDeactivate(currentRoute.params["id"]);
    if (!result) {
      // this.alertService.error('Warning - you are about to lose unsaved changes');
      const result = window.confirm('There are unsaved changes! Are you sure?');
      return of(result);
    }
    return result;
  }
}


type CanDeactivateType = Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree;

export interface CanComponentDeactivate {
  canDeactivate: () => CanDeactivateType;
}
