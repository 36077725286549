import { Injectable } from "@angular/core";
import { PeService } from '@app/core/services/pe.service';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs";
import { SessionActions } from "@app/store/sessions/sessions-action-types";

@Injectable()
export class SessionsEffects {

  constructor(private actions$: Actions,
              private peService: PeService) {
  }

  //#region READ/LOAD
  loadSessionByRnrSessionId$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(SessionActions.loadSessionByRnrSessionId),
        map(action => action),
        mergeMap(action => this.peService.getSessionByRnrSessionId(action.rnrSessionId).pipe(
            map(session => {
                return SessionActions.sessionInserted({ session, rnrSessionId: action.rnrSessionId });
              },
            )
          )
        )));
  //#endregion

  //#region CREATE

  //#endregion

  //#region DELETE

  //#endregion

  //#region UPDATE

  //#endregion
}
