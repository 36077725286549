export class WaitlistModel implements IWaitlistModel {
  effectiveDate?: Date | undefined;
  expirationDate?: Date | undefined;
  waitlistPriority?: string | undefined;

  constructor(data?: IWaitlistModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.effectiveDate = _data["effectiveDate"] ? new Date(_data["effectiveDate"].toString()) : <any>undefined;
      this.expirationDate = _data["expirationDate"] ? new Date(_data["expirationDate"].toString()) : <any>undefined;
      this.waitlistPriority = _data["waitlistPriority"];
    }
  }

  static fromJS(data: any): WaitlistModel {
    data = typeof data === 'object' ? data : {};
    let result = new WaitlistModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["effectiveDate"] = this.effectiveDate ? this.effectiveDate.toISOString() : <any>undefined;
    data["expirationDate"] = this.expirationDate ? this.expirationDate.toISOString() : <any>undefined;
    data["waitlistPriority"] = this.waitlistPriority;
    return data;
  }
}

export interface IWaitlistModel {
  effectiveDate?: Date | undefined;
  expirationDate?: Date | undefined;
  waitlistPriority?: string | undefined;
}
