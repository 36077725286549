import { ICourseVM } from "@app/core/services/WebRegApiClient";
import { CourseActions } from '@app/store/courses/courses-action-types';
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

export interface CoursesState extends EntityState<ICourseVM> {
  error: ApiResponseErrorModel | string | undefined;
}

export function selectId(a: ICourseVM): string {
  return <string>(a.termCode + a.prefix + a.classNumber + (a.sequence ? a.sequence : ''));
}

export const adapter = createEntityAdapter<ICourseVM>(
  {
    selectId: selectId
  }
);

export const initialCoursesState = adapter.getInitialState({
  isCourseLoadedForTerm: {}
});

export const getErrorMessage = (state: CoursesState) => state.error;

export const coursesReducer = createReducer(
  initialCoursesState,

  on(CourseActions.loadCourseSuccess, (state, action) =>
    adapter.setOne(action.course, {...state})
  ),

  on(CourseActions.loadCourseFailure, (state, action) => {
      return {
        ...state,
        error: action.error
      }
    }
  ),

  on(CourseActions.clearError, (state, action) => {
      return {
        ...state,
        error: undefined
      }
    }
  ),
);

export const {
  selectAll,
  selectEntities
} = adapter.getSelectors();
