import { IProgramVM } from "@app/core/services/WebRegApiClient";
import { ApiResponseErrorModel } from "@models/api-response-error.model";
import { createAction, props } from "@ngrx/store";

export const loadAll = createAction(
  "[Admin] Load All Programs"
);

export const loadProgramsByTermCode = createAction(
  "[Program Actions] Load Programs By Term Code",
  props<{ termCode: number }>()
);

export const loadProgramsByTermCodeFailure = createAction(
  "[Program Effect] Load Programs By Term Failure",
  props<{ error: ApiResponseErrorModel | string }>()
);


export const programsInsertedForTerm = createAction(
  "[Program Actions] Programs Successfully Loaded For Term",
  props<{ programs: IProgramVM[], termCode: number }>()
);

export const programsInserted = createAction(
  "[Programs Actions] Programs Loaded",
  props<{ programs: IProgramVM[] }>()
);

export const clearError = createAction(
  "[Programs Actions] Clear Programs Error"
);
