export class ModeModel implements IModeModel {
  rnrModeCode?: string | undefined;
  description?: string | undefined;

  constructor(data?: IModeModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.rnrModeCode = _data["rnrModeCode"];
      this.description = _data["description"];
    }
  }

  static fromJS(data: any): ModeModel {
    data = typeof data === 'object' ? data : {};
    let result = new ModeModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["rnrModeCode"] = this.rnrModeCode;
    data["description"] = this.description;
    return data;
  }
}

export interface IModeModel {
  rnrModeCode?: string | undefined;
  description?: string | undefined;
}
