import { Injectable } from "@angular/core";
import { SchoolsService } from "@app/core/services/schools.service";
import { SchoolActions } from "@app/store/schools/schools-action-types";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, map, mergeMap, of } from "rxjs";
import { TermActions } from "@modules/main/homepage/store/terms/terms-action-types";
import { Store } from "@ngrx/store";
import { AppState } from "@app/store/app.reducer";
import { selectSchoolEntityIdsByTermCode } from "@app/store/schools/schools.selectors";

@Injectable()
export class SchoolsEffects {

    constructor(private actions$: Actions,
                private schoolsService: SchoolsService,
                private store: Store<AppState>) {
    }

    //#region READ/LOAD
    loadSchoolsByTermCode$ = createEffect(
        () => this.actions$
            .pipe(
                ofType(SchoolActions.loadSchoolsByTermCode),
                map(action => action),
                mergeMap(action => this.schoolsService.getByTermCode(action.termCode).pipe(
                    map(schools => {
                            return SchoolActions.loadSchoolsByTermCodeSuccess({ schools, termCode: action.termCode });
                        },
                    ),
                    catchError(error => {
                        return of(SchoolActions.loadSchoolsByTermCodeFailure({
                            error: error
                        }))
                    }),
                )))
    );
    //#endregion

    //#region CREATE

    //#endregion

    //#region DELETE

    removeSchoolsByTermCode$ = createEffect(() => this.actions$.pipe(
        ofType(TermActions.deleteSuccess),
        map(action => action.term.termCode),
        concatLatestFrom(termCode => this.store.select(selectSchoolEntityIdsByTermCode({ termCode: termCode }))),
        filter(([termCode, schools]) => schools != undefined && !!schools.length),
        map(([termCode, schools]) => {
            return SchoolActions.removeSchoolsByTermCode({
                schools: schools,
                termCode: termCode
            })
        })));

    //#endregion

    //#region UPDATE

    //#endregion
}
