import { Injectable } from "@angular/core";
import { DEPARTMENTS, IDepartment } from "@app/core/mocks/department.model";
import { Observable, of } from "rxjs";


@Injectable()
export class DepartmentsService {

  constructor() {
  }

  getDepartmentByPrefixCode(termCode: string | undefined, departmentPrefix: string | undefined): Observable<IDepartment> {
    return of((DEPARTMENTS.filter(x => x.prefix === departmentPrefix)[0]));
  }

  searchDepartmentsBydescription(searchTerm: string | undefined,
    ignoreCase: boolean = true): IDepartment[] {
    return (DEPARTMENTS.filter(x => {
      if (!x.description || !searchTerm) {
        return;
      }

      const description = ignoreCase ? x.description.toLowerCase() : x.description;
      searchTerm = ignoreCase ? searchTerm.toLowerCase() : searchTerm;

      if (description.includes(searchTerm)) {
        return x;
      }

      return;
    }));
  }

  searchDepartmentsByDescriptionOrPrefixCode(searchTerm: string | undefined,
    ignoreCase: boolean = true): IDepartment[] {
    return (DEPARTMENTS.filter(x => {

      let prefix = x?.prefix || '';
      let description = x?.description || '';
      searchTerm = searchTerm || '';

      prefix = ignoreCase ? prefix.toLowerCase() : prefix;
      description = ignoreCase ? description.toLowerCase() : description;
      searchTerm = ignoreCase ? searchTerm?.toLowerCase() : searchTerm;

      if (prefix.includes(searchTerm)
        || description.includes(searchTerm)) {
        return x;
      }

      return;
    }));
  }

  getDepartmentsBySchoolCode(termCode: string | undefined, schoolPrefix: string | undefined): Observable<IDepartment[]> {
    return of((DEPARTMENTS.filter(x => x.schoolPrefix === schoolPrefix).map(r => r)));
  }
}
